import * as tslib_1 from "tslib";
import { OnInit, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { faSlidersH, faMapMarkerAlt, faPhone, faMapPin, faPen, faInfo, faGlobeAmericas, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormControl, Validators } from '@angular/forms';
import { AgmInfoWindow, LatLngLiteral, MapsAPILoader } from '@agm/core';
import { RadiusSearchSettings } from 'src/app/models/RadiusSearchSettings';
import { GenericEntityData, PagedResult } from '../../interfaces/asi.interfaces';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
export class RadiusSearchDisplayComponent {
    constructor(cdRef, radiusDisplayService, geocodeService, zone, mapsAPILoader, ngZone, _sanitizer, partyImageService, versionService, formBuilder) {
        this.cdRef = cdRef;
        this.radiusDisplayService = radiusDisplayService;
        this.geocodeService = geocodeService;
        this.zone = zone;
        this.mapsAPILoader = mapsAPILoader;
        this.ngZone = ngZone;
        this._sanitizer = _sanitizer;
        this.partyImageService = partyImageService;
        this.versionService = versionService;
        this.formBuilder = formBuilder;
        this.isRadius = false;
        this.isMap = false;
        this.mapAPIkeyReady = false;
        this.repeatItems = new Array();
        this.faSlidersH = faSlidersH;
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.faPhoneAlt = faPhone;
        this.faMapPin = faMapPin;
        this.faPen = faPen;
        this.faInfo = faInfo;
        this.faGlobeAmericas = faGlobeAmericas;
        this.faInfoCircle = faInfoCircle;
        this.SuburbOrPostcode = new FormControl();
        this.CitySuburbPostcode = new Array();
        // imageData: any;
        this.radius = new Array();
        this.isAdvancedSearchFilter = false;
        this.isSearchFilter = false;
        // for Austrillia
        this.centerLat = -25;
        this.centerLng = 134;
        this.zoom = 8;
        this.firstMap = false;
        this.sortDistance = {};
        this.isSortDistance = false;
        this.resultSection = 1;
        this.timeout = null;
        this.settings = new RadiusSearchSettings();
        this.SearchFilters = new Array();
        this.AdvanceSearchFilters = new Array();
        this.checkIsValid = false;
        this.citySuburbRadius = '';
        this.defaultPageSize = 10;
        this.currentPage = 0;
        this.pageSize = 0;
        this.radiusSearchResultData = new Array();
        this.postcodeSuburbResults = new Array();
        this.ZipCodesForRadiusAndPostcodes = new Array();
        this.recordsBigArray = new Array();
        this.recordsFilteringArray = new Array();
        this.matSpinLoader = false;
        this.searchAdvanceFilter = '';
        this.searchAdvanceFilterChips = false;
        this.stateAdvancedCtrl = "";
        this.SurroundingHTML = "<div id='innerDiv'></div>";
        this.countries = [];
        this.defaultImagePath = "https://pseudo-imis.s3-ap-southeast-2.amazonaws.com/RadiusSearchDisplay/assets/PictureLogo.png";
        this.contactFieldIsID = false;
        this.languageMultiSimpleCtrl = new Array();
        this.stateSimpleCtrl = new Array();
        this.interestsMultiSimpleCtrl = new Array();
        this.languageMultiAdvancedCtrl = new Array();
        this.interestsMultiAdvancedCtrl = new Array();
        this.DisplayViewData = [];
        this.radiusParameter = 10;
        this.imageData = new BehaviorSubject('');
        this.imageDatas = this.imageData.asObservable();
        this.rHTML = "&lt;div class=&quot;col-lg-4 col-sm-6 mb-sm-4 mb-3&quot;&gt;&lt;div class=&quot;card-inner&quot;&gt;&lt;div class=&quot;media-heading text-center&quot;&gt;&lt;div class=&quot;img-wrap d-flex align-items-center justify-content-center&quot;&gt;&lt;img alt=&quot;[[FullName]]&quot; class=&quot;img-fluid&quot; src=&quot;[[PictureLogo]]&quot; /&gt;&lt;/div&gt;&lt;/div&gt;&lt;div class=&quot;data-wrap p-2 p-sm-3 p-md-4&quot;&gt;&lt;div class=&quot;data-spec mb-2 mb-sm-3 text-center&quot;&gt;&lt;h4 class=&quot;m-0&quot;&gt;[[FullName]]&lt;/h4&gt;&lt;!--&lt;p class=&quot;m-0&quot;&gt;[[Company]]&lt;/p&gt;--&gt;&lt;/div&gt;&lt;div class=&quot;speciality mb-2 mb-sm-3 text-center&quot;&gt;&lt;p class=&quot;m-0&quot;&gt;&lt;strong&gt;Mobile: &lt;/strong&gt; [[Mobile]]&lt;/p&gt;&lt;p class=&quot;m-0&quot;&gt;&lt;strong&gt;Company: &lt;/strong&gt;[[Company]]&lt;/p&gt;&lt;p class=&quot;m-0&quot;&gt;[[FullAddress]]&lt;/p&gt;&lt;/div&gt;&lt;div class=&quot;phone-map row form-row pt-md-3 pt-2&quot;&gt;&lt;div class=&quot;col-sm-12 text-center&quot;&gt;&lt;a class=&quot;mx-1 fab-orng iconParentClass&quot; onmouseenter=&quot;callOpenGoogleMap(event,[[mapID]])&quot; title=&quot;[[FullAddress]]&quot;&gt;&lt;i class=&quot;iconClass fa fa-map-marker&quot;&gt;&lt;/i&gt;&lt;/a&gt;&lt;p class=&quot;mx-1 fab-orng iconParentClass&quot; title=&quot;[[Mobile]]&quot;&gt;&lt;i class=&quot;iconClass fa fa-phone&quot;&gt;&lt;/i&gt;&lt;/p&gt;&lt;a class=&quot;ml-1 mb-1 btn btn-primary map-button view-details-button&quot; href=&quot;/mbrr/profile?Id=[[ID]]&quot; target=&quot;_blank&quot;&gt;View Details&lt;/a&gt;&lt;/div&gt;&lt;/div&gt;&lt;/div&gt;&lt;/div&gt;&lt;/div&gt;";
        this.checkcounter = 0;
        this.totalMenuCount = 0;
        this.megaMenuLimit = 30;
        this.selectMegaMenu = [];
        this.megaMenu = [
            {
                name: 'First',
                subMenu: ['Tabs', 'Accordion', 'Text'],
                count: 0
            },
            {
                name: 'Second',
                subMenu: ['Card', 'Title', 'Team'],
                count: 0
            },
            {
                name: 'Third',
                subMenu: ['Feature', 'Testimonial', 'Price'],
                count: 0
            }
        ];
        this.isMegaSearchFilter = false;
        this.tempSubMegaMenu = [];
        this.megaFilter = [];
        this.tempBigMegaMenu = [];
        this.tempBigMegaSecondaryCode = '';
        this.tempBigMegaSecondaryDescription = '';
        this.isRecaptcha = true;
        this.siteKey = '6LfnSMUeAAAAAO_mmFkHyGLsszYfixIvYvAtBMfK';
        this.firstDatalenght = 0;
        // custom pagination
        this.pageChangeLocation = [[]];
        this.counters = 0;
        this.i = 0;
    }
    citySuburbRadiuschange(event) {
        this.radiusParameter = event;
        this.getPostalCode();
    }
    handleSuccess(e) {
        this.isRecaptcha = true;
        this.SuburbOrPostcode.enable();
    }
    ngOnInit() {
        this.aFormGroup = this.formBuilder.group({
            recaptcha: ['', Validators.required]
        });
        jQuery(document).click((event) => {
            if (jQuery(event.target).closest('#megaDropClose').context.className == 'far fa-times-circle') {
            }
            else if (!jQuery(event.target).closest('#megaDropClose').length) {
                this.isMegaSearchFilter = false;
            }
        });
        this.imageData.subscribe((value) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                if (this.settings && this.settings.displayLayout && this.settings.displayLayout != "" && this.repeaterDiv && this.repeaterDiv.nativeElement) {
                    jQuery('#innerDiv').append(this.repeaterDiv.nativeElement);
                    let search_results = '';
                    jQuery('.removeMeParent .removeMe').each(function () {
                        return tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (this['children'][0].innerHTML.split('onclick="callOpenGoogleMap(event,C') && this['children'][0].innerHTML.split('onclick="callOpenGoogleMap(event,C').length > 1) {
                                var data = (yield this['children'][0].innerHTML.split('onclick="callOpenGoogleMap(event,C')[0]) + 'onclick="callOpenGoogleMap(event,' + this['children'][0].innerHTML.split('onclick="callOpenGoogleMap(event,C')[1];
                                this['children'][0].innerHTML = data;
                            }
                            search_results = search_results + jQuery(this).html();
                        });
                    });
                    jQuery('#innerDiv').html(search_results);
                }
            }, 1000);
        }));
        this.versionService.getVersion().subscribe((version) => {
            var parts = version.split(".");
            let minor = Number(parts[2]);
            let major = Number(parts[0]);
            if (major == 100) {
                this.contactFieldIsID = false;
            }
            else {
                this.contactFieldIsID = true;
            }
        }, err => {
            console.log('Error getting iMIS version.');
        }, () => {
            console.log('Finished getting iMIS version.');
        });
        this.radiusDisplayService.showGoogleKeyMaps.subscribe(value => {
            if (value) {
                this.loadDefaultData(value);
            }
        });
        this.SuburbOrPostcode.valueChanges.subscribe(value => {
            if (value) {
                this.blurValidation = true;
            }
        });
    }
    loadDefaultData(x) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.NoData = false;
            this.blurValidation = false;
            this.submitedValidaiton = false;
            this.isSearchFilter = false;
            this.isAdvancedSearchFilter = false;
            this.searchAdvanceFilter = "";
            this.SurroundingHTML = "<div id='innerDiv'></div>";
            this.DisplayViewData = [];
            this.recordsBigArray = [];
            this.recordsFilteringArray = [];
            this.matSpinLoader = false;
            this.repeatItems = [];
            this.isFitBound = false;
            this.checkIsValid = false;
            this.SuburbOrPostcode.reset();
            this.getCountryMap();
            this.selectMegaMenu = [];
            // this.mapkey.showPreviews.subscribe(value=>{
            // this.radiusDisplayService.getSettings().subscribe(x => {
            this.countries = [];
            this.settings = x;
            if (this.settings && this.settings.images == 'true') {
            }
            else {
                this.settings.images = 'false';
            }
            if (this.settings && this.settings.withoutRadius && (this.settings.withoutRadius == 'true' || JSON.parse(this.settings.withoutRadius) == true)) {
                this.isRadius = true;
                // this.mapAPIkeyReady=false;
            }
            else {
                this.isRadius = false;
                // this.mapAPIkeyReady=true;
            }
            if (this.settings && this.settings.isHideMap) {
                this.isMap = true;
                this.mapAPIkeyReady = false;
            }
            else {
                this.isMap = false;
                this.mapAPIkeyReady = true;
            }
            if (this.settings && this.settings.isRecaptcha) {
                this.isRecaptcha = false;
                this.siteKey = this.settings.SiteKey ? this.settings.SiteKey : '';
                this.SuburbOrPostcode.disable();
            }
            else {
                this.isRecaptcha = true;
                this.SuburbOrPostcode.enable();
            }
            this.countries.push(this.settings.Country);
            this.getCountryMap();
            // setTimeout(() => {
            const searchField = document.getElementById('txtLocationSearchField');
            const options = {
                componentRestrictions: {
                    country: this.countries && this.countries[0] ?
                        this.countries[0] == 'Australia' || this.countries[0] == 'AU' ? 'au' :
                            this.countries[0] == 'New Zealand' || this.countries[0] == 'NZ' ? 'nz' :
                                this.countries[0] == 'Canada' || this.countries[0] == 'CA' ? 'ca' :
                                    this.countries[0] == 'US' || this.countries[0] == 'US' ? ['us', 'pr'] :
                                        this.countries[0] == 'UK' || this.countries[0] == 'GB' || this.countries[0] == 'gb' ? 'uk' : ''
                        : ''
                },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: true,
                types: ["(regions)"]
            };
            this.mapsAPILoader.load().then(() => {
                // this.mapAPIkeyReady=true;
                if (searchField && options && options.componentRestrictions && options.componentRestrictions.country && options.componentRestrictions.country[0] && new google.maps.places.Autocomplete(searchField, options)) {
                    const autocomplete = new google.maps.places.Autocomplete(searchField, options);
                    //  const autocomplete          =  new window['google']['maps']['places']['Autocomplete'](searchField,options)
                    if (autocomplete) {
                        autocomplete.addListener('place_changed', () => {
                            var response = autocomplete.getPlace();
                            this.countryCode = 'uk'; // default country, can be: au, ca, gb, nz, us
                            this.latValue = response.geometry.location.lat();
                            this.lngValue = response.geometry.location.lng();
                            // loop through address_components to get country code
                            for (var i = 0; i < response.address_components.length; i += 1) {
                                var oAddress = response.address_components[i];
                                for (var j = 0; j < oAddress.types.length; j += 1) {
                                    if (oAddress.types[j] === 'country') { /*outputs result if it is country*/
                                        this.countryCode = oAddress.short_name;
                                    }
                                }
                            }
                            if (!this.isRadius) {
                                this.getPostalCode();
                            }
                        });
                        if (!this.isRadius) {
                            let address = new URLSearchParams(window.location.search).get('address');
                            if (address) {
                                this.getLatLng(address, options.componentRestrictions.country[0]);
                            }
                        }
                    }
                }
            });
            //  }, 500);
            this.NoData = false;
            // console.log(this.settings.SearchFilters)
            if (this.settings && this.settings.SearchFilters && this.settings.SearchFilters.length > 0)
                this.settings.SearchFilters = this.settings.SearchFilters.sort((a, b) => a.Position - b.Position);
            if (this.settings && this.settings.AdvanceSearchFilters && this.settings.AdvanceSearchFilters.length > 0)
                this.settings.AdvanceSearchFilters = this.settings.AdvanceSearchFilters.sort((a, b) => a.Position - b.Position);
            if (typeof (this.settings.ResultsPerPage) != 'number') {
                if (this.settings.ResultsPerPage != "") {
                    this.settings.ResultsPerPage = parseInt(this.settings.ResultsPerPage ? this.settings.ResultsPerPage : '9');
                }
                else {
                    this.settings.ResultsPerPage = 20;
                }
            }
            this.loadSettings();
            // });
            // })
        });
    }
    getLatLng(defaultPlace, countrycode) {
        // var defaultPlace = document.getElementById('autocomplete').value;
        let selectoption = { 'address': defaultPlace, 'componentRestrictions': {
                'country': countrycode
            } };
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode(selectoption, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                this.SuburbOrPostcode.setValue(results[0].formatted_address);
                var location = results[0].geometry.location;
                var lat = location.lat();
                var lng = location.lng();
                this.latValue = lat;
                this.lngValue = lng;
                this.countryCode = selectoption.componentRestrictions.country;
                this.getPostalCode();
            }
            else {
                console.log("Geocode was not successful for the following reason: " + status);
            }
        });
    }
    checkwidth(event) {
    }
    leftColWidth() {
        return { 'width': 100 % +'!important' };
    }
    centerChanged(coords) {
    }
    getPostalCode() {
        if (this.countryCode && this.latValue && this.lngValue && this.radiusParameter) {
            this.blurValidation = false;
            this.cdRef.detectChanges();
            this.sortDistance = {};
            // this.SuburbOrPostcode=
            this.ZipCodesForRadiusAndPostcodes = [];
            let c_code = this.countryCode == 'PR' ? 'US' : this.countryCode;
            var apiurl = 'https://www.nadeemh.com/pseudocode/radiussearch/geolookup/' + c_code + '/' + this.latValue + '/' + this.lngValue + '/' + this.radiusParameter + '/';
            const httpcall = new XMLHttpRequest();
            httpcall.open("GET", apiurl);
            httpcall.send();
            httpcall.onreadystatechange = (e) => {
                if (httpcall.readyState == 4 && httpcall.status == 200) {
                    var apiresponse = JSON.parse(httpcall.responseText);
                    this.output = '';
                    apiresponse.forEach(result => {
                        if (result.postcode != null) {
                            if (!this.ZipCodesForRadiusAndPostcodes.includes(result.postcode)) {
                                this.ZipCodesForRadiusAndPostcodes.push(result.postcode);
                                this.sortDistance[result.postcode] = result.distance;
                            }
                        }
                    });
                }
            };
        }
        else {
            this.blurValidation = true;
        }
    }
    pressEnterBtn() {
        this.ShowResultsSection();
    }
    doEvent(event) {
        event.preventDefault();
    }
    clearCheckBox() {
        jQuery(".ncb-inp").attr("checked", false);
        this.megaMenu.forEach((ele, i) => {
            ele.count = 0;
            jQuery('.selectAll' + i).each((index, element) => {
                jQuery(element)[0].checked = false;
            });
            jQuery('.selectAllbtn' + i).each((index, element) => {
                jQuery(element)[0].checked = false;
            });
        });
        this.totalMenuCount = 0;
        this.selectMegaMenu = [];
    }
    megaMenuTab(i) {
        jQuery('.is-active').removeClass('is-active');
        document.getElementsByClassName('mcbMn-menuLink')[i].classList.add('is-active');
        document.getElementsByClassName('mcbMn-tabContent')[i].classList.add('is-active');
        if (jQuery(window).width() < 875) {
            document.getElementsByClassName('mcbMn-rightCol')[0].classList.add('is-active');
        }
    }
    backMegaMenu() {
        jQuery('.mcbMn-rightCol').removeClass('is-active');
    }
    gotChecked(event, i, j) {
        if (event.target.checked) {
            if (this.megaMenuLimit < this.totalMenuCount + 1) {
                jQuery('.aTgInp-maxTagsMsg').css('display', 'block');
                setTimeout(() => {
                    jQuery('.aTgInp-maxTagsMsg').css('display', 'none');
                }, 2000);
                event.stopPropagation();
                event.preventDefault();
            }
            else {
                this.megaMenu[i]['count'] = this.megaMenu[i]['count'] + 1;
                this.totalMenuCount = this.totalMenuCount + 1;
                let data = { row: i, col: j, name: this.megaMenu[i].subMenu[j] };
                this.selectMegaMenu.push(data);
            }
            this.isMegaSearchFilter = true;
            if (this.megaMenu[i].count == this.megaMenu[i].subMenu.length) {
                jQuery('.selectAllbtn' + i).each((index, element) => {
                    jQuery(element)[0].checked = true;
                });
            }
        }
        else {
            this.megaMenu[i]['count'] = this.megaMenu[i]['count'] - 1;
            this.totalMenuCount = this.totalMenuCount - 1;
            this.selectMegaMenu.forEach((ele, index) => {
                if (ele.row == i && ele.col == j) {
                    this.selectMegaMenu.splice(index, 1);
                }
            });
            this.isMegaSearchFilter = true;
            jQuery('.selectAllbtn' + i).each((index, element) => {
                jQuery(element)[0].checked = false;
            });
        }
    }
    selectAll(event, i) {
        if (event.target.checked) {
            jQuery('.selectAll' + i).each((index, element) => {
                jQuery(element)[0].checked = true;
                this.megaMenu[i]['count'] = index + 1;
                let data = { row: i, col: index, name: this.megaMenu[i].subMenu[index] };
                var contains = this.selectMegaMenu.some(elem => {
                    return JSON.stringify(data) === JSON.stringify(elem);
                });
                if (!contains) {
                    this.selectMegaMenu.push(data);
                    this.totalMenuCount = this.totalMenuCount + 1;
                }
            });
        }
        else {
            jQuery('.selectAll' + i).each((index, element) => {
                jQuery(element)[0].checked = false;
                this.megaMenu[i]['count'] = 0;
                let data = { row: i, col: index, name: this.megaMenu[i].subMenu[index] };
                this.selectMegaMenu.some((elem, z) => {
                    if (JSON.stringify(data) === JSON.stringify(elem)) {
                        this.selectMegaMenu.splice(z, 1);
                        this.totalMenuCount = this.totalMenuCount - 1;
                    }
                });
            });
        }
    }
    removeMegaMenu(i, j) {
        let count = 0;
        this.megaMenu.forEach((ele, index) => {
            if (index < i) {
                ele.subMenu.forEach(ele => {
                    count++;
                });
            }
        });
        let index = count + j;
        document.getElementById(i + '+' + j);
        document.getElementById(i + '+' + j).click();
        this.selectMegaMenu.forEach((ele, index) => {
            if (ele.row == i && ele.col == j) {
                this.selectMegaMenu.splice(index, 1);
                setTimeout(() => {
                    this.isMegaSearchFilter = true;
                }, 100);
            }
        });
    }
    getCountryMap() {
        if (this.countries && !this.isMap) {
            this.countries.map((ele, index) => {
                console.log(ele);
                if (ele == 'Australia' || ele == 'AU') {
                    this.countries[index] = 'AU';
                    this.centerLat = -25;
                    this.centerLng = 134;
                    this.zoom = 4;
                }
                else if (ele == 'New Zealand' || ele == 'NZ') {
                    this.countries[index] = 'NZ';
                    this.centerLat = -42;
                    this.centerLng = 172;
                    this.zoom = 5;
                }
                else if (ele == 'Canada' || ele == 'CA') {
                    this.countries[index] = 'CA';
                    this.centerLat = 57;
                    this.centerLng = -101;
                    this.zoom = 4;
                }
                else if (ele == 'United Kingdom' || ele == 'UK' || ele == 'GB') {
                    this.countries[index] = 'GB';
                    this.centerLat = 54;
                    this.centerLng = -2;
                    this.zoom = 6;
                }
                else if (ele == 'United States' || ele == 'US') {
                    this.centerLat = 39;
                    this.centerLng = -101;
                    this.zoom = 4;
                }
            });
        }
    }
    loadSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // fetch the radius for dropdown
            if (typeof this.settings.RadiusIQA === 'string' && this.settings.RadiusIQA.length > 0) {
                let parameters = new Map();
                this.IQAResults(this.settings.RadiusIQA, parameters).then((result) => {
                    this.radius = result;
                    if (this.settings.DefaultRadius) {
                        this.citySuburbRadius = this.settings.DefaultRadius.toString();
                        this.radiusParameter = this.citySuburbRadius;
                    }
                    else {
                        this.citySuburbRadius = "";
                        this.radiusParameter = this.citySuburbRadius;
                    }
                }).catch(error => {
                    this.citySuburbRadius = "";
                    this.radiusParameter = this.citySuburbRadius;
                });
            }
            // check the search filters and fetch the data as per the search filters 
            if (this.settings.SearchFilters && this.settings.SearchFilters.length > 0) {
                this.SearchFilters = [];
                this.settings.SearchFilters.map((ele, index) => {
                    if (ele.FilterType == 'checkedBox' || ele.FilterType == 'freeText') {
                        this.SearchFilters[index] = {
                            data: ele,
                            value: ''
                        };
                    }
                    else {
                        this.radiusDisplayService.getQueryParameters(this.settings.InputIQA).subscribe((result) => {
                            result.Result.Parameters.$values.map((paramObj, paramIndex) => {
                                if (ele.SourceIQAParameter == paramObj.PropertyName) {
                                    let parameters = new Map();
                                    this.IQAResults(ele.IQA, parameters).then((result) => {
                                        if (ele.FilterType == 'megaMenu') {
                                            this.tempBigMegaMenu = result;
                                            this.tempBigMegaSecondaryCode = ele['SecondaryCode'];
                                            this.tempBigMegaSecondaryDescription = ele['SecondaryDescription'];
                                            this.megaMenuLimit = ele['MegaMenuLimit'];
                                            this.megaMenu = [];
                                            let obj = {};
                                            let count = 0;
                                            this.tempSubMegaMenu = result;
                                            this.megaFilter[index] = {
                                                type: paramObj.PropertyName,
                                                data: this.tempSubMegaMenu
                                            };
                                            result.forEach(ele2 => {
                                                if (obj.hasOwnProperty(ele2[ele['PrimaryDescription']])) {
                                                    this.megaMenu[obj[ele2[ele['PrimaryDescription']]]]['subMenu'].push(ele2[ele['SecondaryDescription']]);
                                                }
                                                else {
                                                    this.megaMenu.push({
                                                        name: ele2[ele['PrimaryDescription']],
                                                        subMenu: [ele2[ele['SecondaryDescription']]],
                                                        count: 0,
                                                    });
                                                    obj[ele2[ele['PrimaryDescription']]] = count;
                                                    count++;
                                                }
                                            });
                                            this.SearchFilters[index] = {
                                                type: paramObj.PropertyName,
                                                data: ele,
                                                searchFilterValues: this.searchFilterDefaults(ele, result),
                                                dropdownData: result,
                                                dropdownSettings: this.dropDownSelectChoices(ele.FilterType, ele)
                                            };
                                        }
                                        else {
                                            this.SearchFilters[index] = {
                                                type: paramObj.PropertyName,
                                                data: ele,
                                                searchFilterValues: this.searchFilterDefaults(ele, result),
                                                dropdownData: result,
                                                dropdownSettings: this.dropDownSelectChoices(ele.FilterType, ele)
                                            };
                                        }
                                    }).catch(error => {
                                        // console.log(ele.FilterLabel, ' ', error);
                                    });
                                }
                            });
                        }, error => {
                            console.log(error);
                        });
                    }
                });
            }
            else {
                this.SearchFilters = [];
            }
            // check the advance search filters and fetch the data as per the advance search filters
            if (this.settings.AdvanceSearchFilters && this.settings.AdvanceSearchFilters.length > 0) {
                this.AdvanceSearchFilters = [];
                this.settings.AdvanceSearchFilters.map((ele, index) => {
                    // console.log(index)
                    this.radiusDisplayService.getQueryParameters(this.settings.InputIQA).subscribe((result) => {
                        result.Result.Parameters.$values.map((paramObj, paramIndex) => {
                            if (paramIndex == 0) {
                                let parameters = new Map();
                                this.IQAResults(ele.IQA, parameters).then((result) => {
                                    this.AdvanceSearchFilters[index] = {
                                        type: paramObj.PropertyName,
                                        data: ele,
                                        searchFilterValues: this.searchFilterDefaults(ele, result),
                                        dropdownData: result,
                                        dropdownSettings: this.dropDownSelectChoices(ele.FilterType, ele)
                                    };
                                }).catch(error => {
                                });
                            }
                            // if (ele.SourceIQAParameter == paramObj.PropertyName) {
                            // }
                        });
                    }, error => {
                    });
                });
            }
            else {
                this.AdvanceSearchFilters = [];
            }
        });
    }
    dropDownSelectChoices(selectType, element) {
        let object;
        element.PostcodeValueColumn = element.PostcodeValueColumn.replace(' ', '');
        if (selectType === 'singleselectautocomplete') {
            object = {
                singleSelection: true,
                idField: element.PostcodeValueColumn.replace(' ', ''),
                textField: element.PostcodeDescriptionColumn,
                allowSearchFilter: true,
                closeDropDownOnSelection: true
            };
        }
        else if (selectType === 'multiselectautocomplete') {
            object = {
                singleSelection: false,
                idField: element.PostcodeValueColumn,
                textField: element.PostcodeDescriptionColumn,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 5,
                allowSearchFilter: true
            };
        }
        return object;
    }
    // set the default values in the dropdowns if any
    searchFilterDefaults(data, dataArr) {
        let defaultDataArray = [];
        if (data.Defaultvalue !== '') {
            let defaultValues = data.Defaultvalue.split(',');
            for (var i = 0; i < defaultValues.length; i++) {
                dataArr.filter(dataObj => {
                    if (dataObj[data.PostcodeValueColumn].trim() === defaultValues[i].trim()) {
                        defaultDataArray.push(dataObj);
                    }
                });
            }
        }
        return defaultDataArray;
    }
    IQAResults(IQAQuery, parameters) {
        return new Promise((response, reject) => {
            let returnData;
            let tempData = [];
            this.radiusDisplayService.getIqaResults(IQAQuery, parameters).subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (result.TotalCount <= 100) {
                    returnData = this.getTotalData(result);
                    response(returnData);
                }
                else {
                    let temp = this.getTotalData(result);
                    temp.forEach(resp2 => {
                        tempData.push(resp2);
                    });
                    for (let index = 1; index < Math.floor(result.TotalCount / 100 + 1); index++) {
                        let offset = index * 100;
                        let arr = {};
                        yield this.IQAResultsTotal(IQAQuery, parameters, offset).then(val => {
                            // console.log(val)
                            Object.values(val).forEach(ele => {
                                // console.log(ele)
                                tempData.push(ele);
                            });
                        });
                    }
                    response(tempData);
                }
            }), error => {
                reject(error);
            });
        });
    }
    IQAResultsTotal(IQAQuery, parameters, offset) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((response, reject) => {
                let returnData;
                this.radiusDisplayService.getIqaResultsOff(IQAQuery, parameters, offset).subscribe((result) => {
                    let responseData = new Array();
                    let resultSet = result.Items.$values;
                    let columns = [];
                    for (var i = 0; i < resultSet.length; i++) {
                        let entityData = resultSet[i];
                        let rowData = {};
                        for (var j = 0; j < entityData.Properties.$values.length; j++) {
                            let propData = entityData.Properties.$values[j];
                            let name = propData.Name ? propData.Name : '';
                            let value = '';
                            if (propData.Value) {
                                value = typeof (propData.Value) == 'object' ? propData.Value.$value : propData.Value;
                            }
                            if (!columns.includes(name))
                                columns.push(name);
                            rowData[name] = value;
                        }
                        responseData.push(rowData);
                    }
                    response(responseData);
                }, error => {
                    reject(error);
                });
            });
        });
    }
    getTotalData(result) {
        let responseData = new Array();
        let resultSet = result.Items.$values;
        let columns = [];
        for (var i = 0; i < resultSet.length; i++) {
            let entityData = resultSet[i];
            let rowData = {};
            for (var j = 0; j < entityData.Properties.$values.length; j++) {
                let propData = entityData.Properties.$values[j];
                let name = propData.Name ? propData.Name : '';
                let value = '';
                if (propData.Value) {
                    value = typeof (propData.Value) == 'object' ? propData.Value.$value : propData.Value;
                }
                if (!columns.includes(name))
                    columns.push(name);
                rowData[name] = value;
            }
            responseData.push(rowData);
        }
        return responseData;
    }
    // filter function for Suburb or postcode
    _filter(value) {
        const filterValue = value.toLowerCase();
        return this.CitySuburbPostcode.filter(option => option.Description.toLowerCase().includes(filterValue));
    }
    getSearchedZipsData(event) {
        // if(event && event.target && event.target.value ){
        this.selectedOption = undefined;
        let parameters = new Map();
        let cityZip = this.SuburbOrPostcode.value;
        this.timeouts = clearTimeout(this.timeouts);
        this.timeouts = setTimeout(() => {
            if (cityZip.length > 2) {
                parameters.set("CityZip", this.SuburbOrPostcode.value);
                this.IQAResults(this.settings.CityZipIQA, parameters).then((result) => {
                    this.CitySuburbPostcode = result;
                }).catch(error => {
                });
            }
            else {
                this.CitySuburbPostcode = null;
            }
        }, 300);
    }
    // get the first letter of the first and last name
    getNameFirstLetter(name) {
        var matches = name.match(/\b(\w)/g);
        return matches.join(' ');
    }
    // get the tooltip data of the specialInterests
    getTooltipData(specialInterests) {
        if (specialInterests.length > 150) {
            let splicedSpecialInterests = specialInterests.substring(0, 150) + ' ...';
            return splicedSpecialInterests;
        }
        else {
            return specialInterests;
        }
    }
    // show the clicked info window and closed the previous one
    showInfoWindow(infoWindow, index, m) {
        if (this.previousIW) {
            this.currentIW = infoWindow;
            this.previousIW.close();
        }
        this.previousIW = infoWindow;
        this.DisplayViewData.map(ele => {
            if (ele.mapID == m.mapID) {
                ele.isSelected = true;
            }
            else {
                ele.isSelected = false;
            }
        });
    }
    // close the opened info window on map
    mapWindowClosed(infoWindow, index, m) {
        this.DisplayViewData.map(ele => {
            if (ele.ID == m.ID) {
                ele.isSelected = false;
            }
        });
    }
    // open the bubble as per the selected record
    openGoogleMapBubble(userID) {
        if (this.previousIW) {
            this.previousIW.close();
            this.previousIW = null;
        }
        this.DisplayViewData.map(ele => {
            if (ele.mapID == userID || ele.mapID == 'C' + userID) {
                ele.isSelected = true;
            }
            else {
                ele.isSelected = false;
            }
        });
    }
    // check whether the dropdown is required and need to throw error to user or not
    validateSearchFilters() {
        let x = false;
        // console.log(this.SearchFilters)
        for (var i = 0; i < this.SearchFilters.length; i++) {
            if (this.SearchFilters[i] && this.SearchFilters[i].data.IsMandatory && (this.SearchFilters[i].data.FilterType != 'freeText') && !(this.SearchFilters[i].searchFilterValues.length)) {
                x = true;
            }
            else if (this.SearchFilters[i].data.IsMandatory && this.SearchFilters[i].data.FilterType == 'freeText' && !(this.SearchFilters[i].value)) {
                x = true;
            }
        }
        return x;
    }
    // fetch the results as per the search button
    ShowResultsSection() {
        this.submitedValidaiton = true;
        this.isShowOrHide = false;
        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isFitBound = false;
            if (((this.SuburbOrPostcode.value == '' || this.SuburbOrPostcode.value == null || this.citySuburbRadius == '') && !this.isRadius) || this.validateSearchFilters()) {
                this.checkIsValid = true;
                this.submitedValidaiton = false;
                console.log('check validation ');
                return false;
            }
            else {
                this.SurroundingHTML = "<div id='innerDiv'></div>";
                this.recordsFilteringArray = [];
                this.recordsBigArray = [];
                this.DisplayViewData = [];
                this.repeatItems = [];
                this.matSpinLoader = true;
                this.isSearchFilter = true;
                this.NoData = false;
                this.searchAdvanceFilter = "";
                this.isAdvancedSearchFilter = false;
                this.AdvanceSearchFilters.map(ele => {
                    ele.searchFilterValues = [];
                });
                if (this.previousIW) {
                    this.previousIW = null;
                }
                let resultsPerPage = this.settings.ResultsPerPage;
                if (this.settings.InputIQA != null) {
                    yield this.getUserData(this.settings.InputIQA, 0, resultsPerPage, 'simple');
                    // console.log('step 1')  
                    setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        yield this.getUserData(this.settings.InputIQA, 0, 0, 'advanced');
                    }), 300);
                }
                let countryCode = this.CitySuburbPostcode.filter(ele => {
                    if (ele.ZipLocality == this.SuburbOrPostcode.value) {
                        return ele;
                    }
                });
                return true;
            }
        }), 400);
    }
    // fetch the user data from API
    getUserData(IQAQuery, offset, pageSize, dataType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.radiusDisplayService.getQueryParameters(IQAQuery).subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.IqaPagedParameters = result;
                let zipArray = new Array();
                let len = 0;
                let currArray = new Array();
                let megaMenuList;
                if (this.selectMegaMenu && this.selectMegaMenu.length > 0) {
                    this.selectMegaMenu.forEach(resp => {
                        this.tempBigMegaMenu.forEach(items => {
                            if (items[this.tempBigMegaSecondaryDescription] == resp.name) {
                                megaMenuList = megaMenuList ? megaMenuList + items[this.tempBigMegaSecondaryCode] + "," : items[this.tempBigMegaSecondaryCode] + ",";
                                len += megaMenuList.length + 9;
                            }
                        });
                    });
                }
                if (this.SearchFilters && this.SearchFilters.length > 0) {
                    this.SearchFilters.forEach(resp => {
                        if (resp.searchFilterValues && resp.searchFilterValues.length > 0) {
                            resp.searchFilterValues.forEach(element => {
                                len += element[resp.data.PostcodeValueColumn].length + 9;
                            });
                        }
                    });
                }
                for (var i = 0; i < this.ZipCodesForRadiusAndPostcodes.length; i++) {
                    let item = this.ZipCodesForRadiusAndPostcodes[i];
                    len += item.length + 9;
                    currArray.push(item);
                    // if(len>=1000){
                    //   let zips = currArray.join();
                    //   currArray = new Array();
                    //   len=0;
                    // }
                }
                let zipParms;
                if (len < 1000) {
                    zipParms = currArray.join();
                }
                console.log("postal code length ", len);
                if (len > 0 || this.isRadius) {
                    let zips = currArray.join();
                }
                let parameters = new Map();
                for (var p = 0; p < result.Result.Parameters.$values.length; p++) {
                    let parmName = result.Result.Parameters.$values[p].PropertyName;
                    if (result.Result.Parameters.$values[p].PropertyName == this.settings.PostcodeZipColumn) {
                        parameters[parmName] = len < 1000 ? zipParms : ''; //this.ZipCodesForRadiusAndPostcodes.join()
                    }
                    else {
                        parameters[parmName] = '';
                    }
                }
                this.SearchFilters.map(ele => {
                    if (ele && ele['searchFilterValues'] && ele['searchFilterValues'].length > 0) {
                        for (var p = 0; p < result.Result.Parameters.$values.length; p++) {
                            let parmName = result.Result.Parameters.$values[p];
                            if (ele.type == result.Result.Parameters.$values[p].PropertyName) {
                                parameters[result.Result.Parameters.$values[p].PropertyName] = ele['searchFilterValues'].map(function (ele1) { return ele1[ele.data.PostcodeValueColumn]; }).toString();
                            }
                        }
                    }
                    else {
                        for (var p = 0; p < result.Result.Parameters.$values.length; p++) {
                            let parmName = result.Result.Parameters.$values[p];
                            if (ele.data.SourceIQAParameter == result.Result.Parameters.$values[p].PropertyName && ele.value) {
                                parameters[result.Result.Parameters.$values[p].PropertyName] = ele.value.toString();
                            }
                            else {
                                // parameters[result.Result.Parameters.$values[p].PropertyName] = '';
                            }
                        }
                    }
                });
                let prevValue = [];
                if (this.megaFilter && this.megaFilter.length > 0) {
                    for (var p = 0; p < result.Result.Parameters.$values.length; p++) {
                        this.megaFilter.forEach(fil => {
                            if (result.Result.Parameters.$values[p].PropertyName == fil.type) {
                                parameters[result.Result.Parameters.$values[p].PropertyName] = megaMenuList ? megaMenuList.slice(0, -1) : '';
                            }
                        });
                    }
                }
                if (this.isRadius) {
                    this.getquery(IQAQuery, zipArray, parameters, offset, pageSize, prevValue, dataType, 0);
                }
                else {
                    yield this.getquery(IQAQuery, zipArray, parameters, offset, pageSize, prevValue, dataType, 0);
                    for (let x = 0; x < zipArray.length; x++) {
                        if (x == 0) {
                            this.getquery(IQAQuery, zipArray, parameters, offset, pageSize, prevValue, dataType, x);
                        }
                    }
                }
            }), error => {
            });
        });
    }
    getquery(IQAQuery, zipArray, parameters, offset, pageSize, prevValue, dataType, x) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.matSpinLoader = true;
            yield this.IQAPagedZipresults(IQAQuery, this.isRadius ? '' : zipArray[x], this.settings.PostcodeZipColumn, parameters, offset, pageSize).then((value) => {
                let index = 0;
                value.forEach(element => {
                    element.mapID = element.mapID + index;
                    if (!this.isRadius) {
                        if (element.Zip) {
                            this.ZipCodesForRadiusAndPostcodes.forEach(x => {
                                if (String(element.Zip).substring(0, 3) == x.substring(0, 3) && element.Zip.includes(x)) {
                                    prevValue.push(element);
                                }
                            });
                        }
                    }
                    else {
                        // console.log('else------------->>>>>>',this.isRadius)
                        prevValue.push(element);
                    }
                    index++;
                });
                if (dataType == 'simple' && !this.settings.RandomizeResults) {
                    if (x == zipArray.length - 1 || zipArray.length == 0) {
                        // this.matSpinLoader = false;
                    }
                    else {
                        x++;
                        this.getquery(IQAQuery, zipArray, parameters, offset, pageSize, prevValue, dataType, x);
                    }
                    if ((value.length > 0 && prevValue.length <= this.settings.ResultsPerPage) || (x == zipArray.length - 1 && this.firstDatalenght < prevValue.length) || (x == zipArray.length - 1 && 0 == prevValue.length)) {
                        this.firstDatalenght = prevValue.length;
                        if (prevValue.length > 0) {
                            // console.log(prevValue)
                            this.setSimpleData(prevValue.slice(0, this.settings.ResultsPerPage));
                        }
                    }
                }
                else if (dataType == 'advanced') {
                    //we are losing scope of component, generating error.
                    if (x == zipArray.length - 1 || zipArray.length == 0) {
                    }
                    else {
                        x++;
                        this.getquery(IQAQuery, zipArray, parameters, offset, pageSize, prevValue, dataType, x);
                    }
                    this.recordsBigArray = prevValue;
                    this.recordsFilteringArray = prevValue;
                    // console.log('step 1 : ', this.recordsBigArray)
                    const arr1 = this.getUniqueListBy(prevValue, 'Zip');
                    let result = [];
                    if (this.settings.isSortByDistance) {
                        arr1.forEach((element, i) => {
                            Object.keys((this.sortDistance)).forEach((post, j) => {
                                if (this.settings.Country == 'US' || this.settings.Country == 'AU' || this.settings.Country == 'NZ') {
                                    if (String(element).startsWith(post)) {
                                        result.push(element);
                                    }
                                }
                                else {
                                    if (String(element).substring(0, 3) == post.substring(0, 3) && String(element).includes(post)) {
                                        result.push(element);
                                    }
                                }
                            });
                        });
                        let newArr = [];
                        result.forEach(element => {
                            prevValue.forEach(ele => {
                                if (element == ele.Zip) {
                                    let isinclude = newArr.some((val) => {
                                        if ((val.ID == ele.ID && ele.ID != undefined) || (val.Event == ele.Event && ele.Event != undefined)) {
                                            // return true
                                        }
                                    });
                                    if (!isinclude) {
                                        newArr.push(ele);
                                    }
                                }
                            });
                        });
                        if (!this.isRadius) {
                            this.recordsBigArray = newArr;
                            // console.log('step 2 : ', this.recordsBigArray)
                            this.recordsFilteringArray = newArr;
                        }
                    }
                    console.log("array length ", this.recordsBigArray.length);
                    if (this.settings.RandomizeResults) {
                        this.recordsBigArray = this.shuffle(this.recordsBigArray);
                        this.setSimpleData(this.recordsBigArray.slice(0, this.settings.ResultsPerPage));
                    }
                    else if (this.settings.isSortByDistance) {
                        for (let i = 0; i < this.recordsBigArray.length; i++) {
                            Object.keys(this.sortDistance).forEach(x => {
                                if (String(this.recordsBigArray[i].Zip).substring(0, 3) == x.substring(0, 3) && this.recordsBigArray[i].Zip.includes(x)) {
                                    this.recordsBigArray[i] = Object.assign({}, this.recordsBigArray[i], { distance: this.sortDistance[x] });
                                }
                            });
                        }
                        this.recordsBigArray.sort(function (a, b) {
                            return a['distance'] - b['distance'];
                        });
                        // console.log(this.recordsBigArray)
                        this.setSimpleData(this.recordsBigArray.slice(0, this.settings.ResultsPerPage));
                    }
                    else {
                        // console.log(this.recordsBigArray)
                        this.setSimpleData(this.recordsBigArray.slice(0, this.settings.ResultsPerPage));
                    }
                }
            }).catch(error => {
                console.log(error.message);
            });
        });
    }
    getUniqueListBy(arr, key) {
        // console.log(arr, key)
        return [...new Map(arr.map((item, i) => [item[key], item['Zip']])).values()];
    }
    setProfileImages() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let index = 0; index < this.DisplayViewData.length; index++) {
                const ele = this.DisplayViewData[index];
                // setTimeout(()=>{
                var contactKeyName = 'ContactKey'; //repplace with this.settings.ContactKeyColumn later
                if (this.contactFieldIsID) {
                    if (this.settings.ContactIDColumn != null && this.settings.ContactIDColumn.length > 0) {
                        contactKeyName = this.settings.ContactIDColumn.toUpperCase();
                    }
                }
                else {
                    if (this.settings.ContactKeyColumn != null && this.settings.ContactKeyColumn.length > 0) {
                        contactKeyName = this.settings.ContactKeyColumn;
                    }
                }
                if (typeof ele !== 'undefined' && typeof ele[contactKeyName] !== 'undefined' && ele[contactKeyName] && String(ele[contactKeyName]).trim() !== '') {
                    var contactKey = ele[contactKeyName];
                    yield this.gettingImage(contactKey, index);
                }
                if (index == this.DisplayViewData.length - 1) {
                    this.imageData.next('change');
                    setTimeout(() => {
                        this.submitedValidaiton = false;
                    }, 1000);
                }
            }
            // await this.DisplayViewData.map(async(ele, index) => {
            //   // },index*500);
            // });
        });
    }
    gettingImage(contactKey, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log("step 1 getting image")
            yield this.partyImageService.getPartyImagePath2(contactKey).then((res) => {
                var imagePath = res['VirtualPath'];
                imagePath = imagePath.replace("~", "");
                if (imagePath.indexOf('default') == -1) {
                    var matchStr = this.defaultImagePath;
                    if (matchStr && this.repeatItems && this.repeatItems[index] && this.repeatItems[index].indexOf(matchStr)) {
                        var pos = this.repeatItems[index].indexOf(matchStr);
                        if (pos >= 0) {
                            var repeatText = this.repeatItems[index];
                            var prefix = repeatText.substring(0, pos);
                            var pos2 = pos + matchStr.length;
                            var postfix = repeatText.substring(pos2, repeatText.length);
                            var newItem = prefix + imagePath + postfix;
                            this.repeatItems[index] = newItem;
                            this.imageData.next('value');
                        }
                    }
                }
            });
        });
    }
    checkCity(value) {
    }
    onSelectedOption(isSelected, id) {
        if (isSelected) {
            setTimeout(() => {
                const option = this.CitySuburbPostcode.filter(bt => bt.id == id);
                if (option.length > 0) {
                    this.selectedOption = option[0];
                }
            });
        }
    }
    setSimpleData(value) {
        this.DisplayViewData = value;
        if (this.paginator) {
            this.paginator.firstPage();
        }
        this.convertCustomHTML(this.DisplayViewData);
        this.matSpinLoader = false;
        if (!this.settings.isNoMap) {
            if (this.DisplayViewData.length == 1 || this.DisplayViewData.length == 0) {
                this.isFitBound = false;
                this.countries[0] = this.settings.Country;
                if (this.DisplayViewData.length == 0) {
                    this.getCountryMap();
                    this.errorMessage = " No data found. Please try with a different criteria.";
                    this.NoData = true;
                    this.submitedValidaiton = false;
                }
                else {
                    this.NoData = false;
                }
            }
            else {
                this.NoData = false;
                this.isFitBound = true;
            }
            if (this.DisplayViewData && this.DisplayViewData.length && this.DisplayViewData.length > 0)
                this.pageChangeLocation[0] = [];
            let arrDist = [];
            this.DisplayViewData.map((ele, index) => {
                setTimeout(() => {
                    if (this.DisplayViewData.length == 1) {
                        this.centerLng = value[0].lng;
                        this.centerLat = value[0].lat;
                        this.zoom = 4;
                    }
                    if (typeof ele !== 'undefined' && typeof ele[this.settings.GoogleAddressColumn] !== 'undefined' && ele[this.settings.GoogleAddressColumn].trim() !== '') {
                        if (!this.isMap) {
                            this.geocodeService.geocodeAddress(ele[this.settings.GoogleAddressColumn])
                                .subscribe((locations) => {
                                if (locations && locations.length > 0) {
                                    ele['lat'] = locations[0].geometry.location.lat();
                                    ele['lng'] = locations[0].geometry.location.lng();
                                    for (let i = 0; i < arrDist.length; i++) {
                                        if (arrDist[i].lat == ele['lat'] && arrDist[i].lng == ele['lng']) {
                                            ele['lat'] = ele['lat'] + 0.0001;
                                            ele['lng'] = ele['lng'] + 0.0001;
                                        }
                                    }
                                    arrDist.push({ lat: ele['lat'], lng: ele['lng'] });
                                    if (this.DisplayViewData.length == 1) {
                                        this.centerLat = locations[0].geometry.location.lat();
                                        this.centerLng = locations[0].geometry.location.lng();
                                        this.zoom = 12;
                                    }
                                    this.pageChangeLocation[0][index] = ({ lat: ele['lat'], lng: ele['lng'] });
                                }
                                else {
                                    this.centerLng = value[0].lng;
                                    this.centerLat = value[0].lat;
                                }
                                document.getElementById('agmMapClass').click();
                            });
                        }
                    }
                    // console.log(arrDist)
                    console.log("==============finishted ===============");
                    if (index == this.DisplayViewData.length - 1) {
                        this.submitedValidaiton = false;
                    }
                }, index * 1500);
            });
            // console.log(this.DisplayViewData[0],this.DisplayViewData[0].lat)
        }
    }
    callIndexFunction(event, id) {
        window['angularComponentReference'] = { component: this, zone: this.ngZone, openGoogleFunction: (id) => {
                this.openGoogleMapBubble(id);
            } };
        event.preventDefault();
    }
    shuffle(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
    // fetcht the user data to show in the table/card/grid etc
    IQAPagedZipresults(IQAQuery, zipArray, zipParmName, parameters, offset, pageSize) {
        return new Promise((response, reject) => {
            this.radiusDisplayService.getIqaPagedZipResults(IQAQuery, zipArray, zipParmName, parameters, offset, pageSize).subscribe((result) => {
                let responseData = new Array();
                let resultSet = result.Items.$values;
                let columns = [];
                for (var i = 0; i < resultSet.length; i++) {
                    let entityData = resultSet[i];
                    let rowData = {};
                    for (var j = 0; j < entityData.Properties.$values.length; j++) {
                        let propData = entityData.Properties.$values[j];
                        let name = propData.Name ? propData.Name : '';
                        let value = '';
                        if (propData.Value) {
                            value = typeof (propData.Value) == 'object' ? propData.Value.$value : propData.Value;
                        }
                        if (!columns.includes(name))
                            columns.push(name);
                        rowData[name] = value;
                    }
                    responseData.push(rowData);
                }
                response(responseData);
            }, error => {
                reject(error);
            });
        });
    }
    pageChange(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.previousIW) {
                this.previousIW.close();
                this.previousIW = null;
            }
            this.DisplayViewData = this.recordsBigArray.slice((event.pageSize * event.pageIndex), (event.pageSize * (event.pageIndex + 1)));
            yield this.convertCustomHTML(this.DisplayViewData);
            if (this.pageChangeLocation && this.pageChangeLocation[event.pageIndex]) {
                this.DisplayViewData.map((ele, index) => {
                    if (this.pageChangeLocation[event.pageIndex] && this.pageChangeLocation[event.pageIndex][index] && this.pageChangeLocation[event.pageIndex][index].lat && this.pageChangeLocation[event.pageIndex] && this.pageChangeLocation[event.pageIndex][index] && this.pageChangeLocation[event.pageIndex][index].lng)
                        ele['lat'] = this.pageChangeLocation[event.pageIndex][index].lat;
                    ele['lng'] = this.pageChangeLocation[event.pageIndex][index].lng;
                    document.getElementById('agmMapClass').click();
                });
            }
            else {
                this.pageChangeLocation.push([]);
                let arrDist = [];
                this.DisplayViewData.map((ele, index) => {
                    setTimeout(() => {
                        if (ele[this.settings.GoogleAddressColumn].trim() !== '') {
                            if (!this.isMap) {
                                this.geocodeService.geocodeAddress(ele[this.settings.GoogleAddressColumn])
                                    .subscribe((locations) => {
                                    if (locations && locations.length > 0) {
                                        ele['lat'] = locations[0].geometry.location.lat();
                                        ele['lng'] = locations[0].geometry.location.lng();
                                        for (let i = 0; i < arrDist.length; i++) {
                                            if (arrDist[i].lat == ele['lat'] && arrDist[i].lng == ele['lng']) {
                                                // console.log(arrDist[i].lat,'same',arrDist[i].lng,'==', index)
                                                ele['lat'] = ele['lat'] + 0.0001;
                                                ele['lng'] = ele['lng'] + 0.0001;
                                            }
                                        }
                                        arrDist.push({ lat: ele['lat'], lng: ele['lng'] });
                                        this.pageChangeLocation[event.pageIndex].push({ lat: ele['lat'], lng: ele['lng'] });
                                    }
                                    document.getElementById('agmMapClass').click();
                                });
                            }
                        }
                    }, index * 500);
                });
            }
        });
    }
    do(event) {
        event.preventDefault();
    }
    // remove any value from advance search
    removeAdvanceSearchData(mainIndex, index) {
        this.isFitBound = true;
        this.AdvanceSearchFilters[mainIndex].searchFilterValues = this.AdvanceSearchFilters[mainIndex].searchFilterValues.filter((ele, langIndex) => langIndex !== index);
    }
    // advance search toggle data change
    searchToggle(toggleClick) {
        if (toggleClick) {
            this.searchAdvanceFilterChips = true;
            this.getSearchRecords(false);
        }
        else {
            this.searchAdvanceFilterChips = false;
        }
    }
    getSearchRecords(isAdvanced = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isSearchFilter) {
                let filteredData = [];
                let filteredRecords = [];
                this.AdvanceSearchFilters.map((advSearchObj, advSearchIndex) => {
                    if (this.IqaPagedParameters && this.IqaPagedParameters['Result'] && this.IqaPagedParameters['Result']['Parameters'] && this.IqaPagedParameters['Result']['Parameters']['$values']) {
                        this.IqaPagedParameters['Result']['Parameters']['$values'].map((paramObj, paramIndex) => {
                            if (advSearchObj.type == paramObj.PropertyName) {
                                advSearchObj.searchFilterValues.map((searchObj, searchIndex) => {
                                    filteredRecords.push(searchObj[advSearchObj.data.PostcodeValueColumn]);
                                });
                            }
                        });
                    }
                });
                if (filteredRecords.length) {
                    for (var i = 0; i < filteredRecords.length; i++) {
                        for (var j = 0; j < this.recordsFilteringArray.length; j++) {
                            let IqaPagedParametersFiltered = [];
                            if (this.IqaPagedParameters && this.IqaPagedParameters['Result'] && this.IqaPagedParameters['Result']['Properties'] && this.IqaPagedParameters['Result']['Properties']['$values']) {
                                this.IqaPagedParameters['Result']['Properties']['$values'].map((paramObj, paramIndex) => {
                                    if (this.recordsFilteringArray[j][paramObj.Alias != "" ? paramObj.Alias : paramObj.PropertyName]) {
                                        IqaPagedParametersFiltered.push(String(this.recordsFilteringArray[j][paramObj.Alias != "" ? paramObj.Alias : paramObj.PropertyName]).split(','));
                                    }
                                });
                                IqaPagedParametersFiltered.map(ele => {
                                    if (ele.includes(filteredRecords[i])) {
                                        filteredData.push(this.recordsFilteringArray[j]);
                                    }
                                });
                            }
                        }
                    }
                    // provide the filtered data to paging array and display array
                    this.recordsBigArray = filteredData;
                    this.applyFilter(this.searchAdvanceFilter, false);
                    if (this.recordsBigArray.length <= this.settings.ResultsPerPage) {
                        this.DisplayViewData = this.recordsBigArray;
                        if (this.paginator) {
                            this.paginator.firstPage();
                        }
                        this.convertCustomHTML(this.DisplayViewData);
                        this.mapSetting();
                    }
                    else {
                        this.DisplayViewData = [];
                        for (var x = 0; x < this.settings.ResultsPerPage; x++) {
                            this.DisplayViewData.push(this.recordsBigArray[x]);
                        }
                        if (this.paginator) {
                            this.paginator.firstPage();
                        }
                        this.convertCustomHTML(this.DisplayViewData);
                        this.mapSetting();
                    }
                }
                else if (this.searchAdvanceFilter != "") {
                    this.applyFilter(this.searchAdvanceFilter, true);
                    if (this.recordsBigArray.length <= this.settings.ResultsPerPage) {
                        this.DisplayViewData = this.recordsBigArray;
                        if (this.paginator) {
                            this.paginator.firstPage();
                        }
                        this.convertCustomHTML(this.DisplayViewData);
                        if (this.DisplayViewData && this.DisplayViewData.length && this.DisplayViewData.length == 1) {
                            this.isFitBound = false;
                            // this.getCountryMap()
                        }
                        else {
                            this.isFitBound = true;
                        }
                        if (this.DisplayViewData && this.DisplayViewData.length == 0) {
                            this.isFitBound = false;
                            this.getCountryMap();
                        }
                        else {
                            this.isFitBound = true;
                        }
                        this.mapSetting();
                    }
                    else {
                        this.DisplayViewData = [];
                        for (var x = 0; x < this.settings.ResultsPerPage; x++) {
                            this.DisplayViewData.push(this.recordsBigArray[x]);
                        }
                        if (this.paginator) {
                            this.paginator.firstPage();
                        }
                        this.convertCustomHTML(this.DisplayViewData);
                        this.mapSetting();
                    }
                }
                else if (isAdvanced) {
                    let resultsPerPage = this.settings.ResultsPerPage;
                    this.DisplayViewData = this.recordsFilteringArray.slice(0, resultsPerPage);
                    this.recordsBigArray = this.recordsFilteringArray;
                    if (this.paginator) {
                        this.paginator.firstPage();
                    }
                    this.convertCustomHTML(this.DisplayViewData);
                    if (this.DisplayViewData && this.DisplayViewData.length && this.DisplayViewData.length > 0) {
                        this.mapSetting();
                    }
                    this.counters++;
                }
            }
        });
    }
    mapSetting() {
        if (this.DisplayViewData.length == 1 || this.DisplayViewData.length == 0) {
            this.isFitBound = false;
            this.countries[0] = this.settings.Country;
            if (this.DisplayViewData.length == 0) {
                this.getCountryMap();
                this.errorMessage = " No data found. Please try with a different criteria.";
                this.NoData = true;
                this.submitedValidaiton = false;
            }
            else {
                this.NoData = false;
            }
        }
        else {
            this.NoData = false;
            this.isFitBound = true;
        }
        if (!this.settings.isNoMap) {
            let arrDist = [];
            this.DisplayViewData.map((ele, index) => {
                setTimeout(() => {
                    if (ele[this.settings.GoogleAddressColumn].trim() !== '') {
                        if (!this.isMap) {
                            this.geocodeService.geocodeAddress(ele[this.settings.GoogleAddressColumn])
                                .subscribe((locations) => {
                                if (locations && locations.length > 0) {
                                    ele['lat'] = locations[0].geometry.location.lat();
                                    ele['lng'] = locations[0].geometry.location.lng();
                                    for (let i = 0; i < arrDist.length; i++) {
                                        if (arrDist[i].lat == ele['lat'] && arrDist[i].lng == ele['lng']) {
                                            // console.log(arrDist[i].lat,'same',arrDist[i].lng,'==', index)
                                            ele['lat'] = ele['lat'] + 0.0001;
                                            ele['lng'] = ele['lng'] + 0.0001;
                                        }
                                    }
                                    arrDist.push({ lat: ele['lat'], lng: ele['lng'] });
                                    if (this.DisplayViewData.length == 1) {
                                        this.centerLat = locations[0].geometry.location.lat();
                                        this.centerLng = locations[0].geometry.location.lng();
                                        this.zoom = 12;
                                    }
                                }
                                document.getElementById('agmMapClass').click();
                            });
                        }
                    }
                }, index * 500);
            });
        }
    }
    // function works once user stop typing in advance search filter input for 2 seconds
    onStopSearchTyping() {
        clearTimeout(this.timeout);
        var that = this;
        this.timeout = setTimeout(function () {
            this.isFitBound = true;
            that.getSearchRecords(true);
        }, 2000);
    }
    applyFilter(searchAdvanceFilter, isNew = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (searchAdvanceFilter.trim().length > 0) {
                let filterSearchRecords = [];
                if (isNew) {
                    this.recordsFilteringArray.filter(ele => {
                        for (let i = 0; i < this.IqaPagedParameters['Result']['Properties']['$values'].length; i++) {
                            if (this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias && this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "") {
                                if (this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != 'PictureLogo') {
                                    if (ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName] && String(ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName]).toLowerCase().includes(String(searchAdvanceFilter).toLowerCase())) {
                                        filterSearchRecords.push(ele);
                                        break;
                                    }
                                }
                            }
                            else {
                                if (this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName != 'PictureLogo') {
                                    if (ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName] && String(ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName]).toLowerCase().includes(String(searchAdvanceFilter.toLowerCase()))) {
                                        filterSearchRecords.push(ele);
                                        break;
                                    }
                                }
                            }
                        }
                    });
                }
                else {
                    this.recordsBigArray.filter(ele => {
                        for (let i = 0; i < this.IqaPagedParameters['Result']['Properties']['$values'].length; i++) {
                            if (this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias && this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "") {
                                if (this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != 'PictureLogo') {
                                    if (ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName] && String(ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName]).toLowerCase().includes(String(searchAdvanceFilter.toLowerCase()))) {
                                        filterSearchRecords.push(ele);
                                        break;
                                    }
                                }
                            }
                            else {
                                if (this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName != 'PictureLogo') {
                                    if (ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName] && String(ele[this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias != "" ? this.IqaPagedParameters['Result']['Properties']['$values'][i].Alias : this.IqaPagedParameters['Result']['Properties']['$values'][i].PropertyName]).toLowerCase().includes(String(searchAdvanceFilter.toLowerCase()))) {
                                        filterSearchRecords.push(ele);
                                        break;
                                    }
                                }
                            }
                        }
                    });
                }
                this.recordsBigArray = filterSearchRecords;
            }
        });
    }
    // get background color for the selected row
    getColor(isSelected) {
        return isSelected ? 'lightgrey' : '';
    }
    ngAfterViewInit() {
    }
    ngAfterViewChecked() {
    }
    unjsonEscape(str) {
        if (typeof str === 'string' && str.length > 0)
            str = str.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"');
        return str;
    }
    convertCustomHTML(resultSet) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            window['angularComponentReference'] = { component: this, zone: this.ngZone, openGoogleFunction: (id) => {
                    this.openGoogleMapBubble(id);
                } };
            this.repeatItems = [];
            this.SurroundingHTML = this.unjsonEscape(this.settings.OpeningHTML);
            let bodyHTML = this.unjsonEscape(this.settings.RepeaterHTML);
            var contactKeyName;
            if (this.contactFieldIsID) {
                if (this.settings.ContactIDColumn != null && this.settings.ContactIDColumn.length > 0) {
                    contactKeyName = this.settings.ContactIDColumn.toUpperCase();
                }
            }
            for (var i = 0; i < resultSet.length; i++) {
                if (this.settings.images) {
                    // console.log(resultSet[i].PictureLogo)
                    // resultSet[i].PictureLogo = this.defaultImagePath;
                }
                // convert base64 into image code starts
                // if (resultSet[i].PictureLogo != "") {
                //   resultSet[i].PictureLogo = "https://d1qtx34w5zi8yw.cloudfront.net/RadiusSearchDisplay/assets/PictureLogo.png";
                //   try {
                //     const byteString = window.atob(resultSet[i].PictureLogo);
                //     const arrayBuffer = new ArrayBuffer(byteString.length);
                //     const int8Array = new Uint8Array(arrayBuffer);
                //     for (let i = 0; i < byteString.length; i++) {
                //       int8Array[i] = byteString.charCodeAt(i);
                //     }
                //     const blob = new Blob([int8Array], { type: 'image/jpeg' });
                //     let imageData = this._sanitizer.bypassSecurityTrustResourceUrl('data:' + blob.type + ';base64,'
                //       + resultSet[i].PictureLogo);
                //     if (blob.size > 1) {
                //       resultSet[i].PictureLogo = imageData['changingThisBreaksApplicationSecurity'];
                //     } else {
                //       resultSet[i].PictureLogo = "https://d1qtx34w5zi8yw.cloudfront.net/RadiusSearchDisplay/assets/PictureLogo.png";
                //     }
                //   } catch (ex) { }
                // } else {
                //   resultSet[i].PictureLogo = "https://d1qtx34w5zi8yw.cloudfront.net/RadiusSearchDisplay/assets/PictureLogo.png";
                // }
                // convert base64 into image code ends
                this.cdRef.detectChanges();
                let rowSet = resultSet[i];
                let currHTML = bodyHTML.slice(0, bodyHTML.length);
                // console.log(currHTML)
                for (var key in rowSet) {
                    let val = rowSet[key].toString();
                    // console.log('=================start================')
                    // console.log(key,'+++',val)  
                    let match = '[[' + key + ']]';
                    let match2 = '[[' + key.replace(' ', '') + ']]';
                    currHTML = currHTML.replace(match, val);
                    currHTML = currHTML.replace(match2, val.replace("'", "\'"));
                    // if(key=='PictureLogo'){
                    //   console.log(currHTML)
                    // }
                }
                // console.log(this.contactFieldIsID,'this.contactFieldIsID')
                if (this.contactFieldIsID) {
                    let val = rowSet[key].toString();
                    let match = '[[' + contactKeyName + ']]';
                    currHTML = currHTML.replace(match, val.replace("'", "''"));
                }
                // const fiteredArr = this.filterpipe.transform(currHTML,'html');
                this.repeatItems.push(currHTML);
                // const fiteredArr = this.filterpipe.transform(currHTML,'html');
            }
            // console.log(this.repeatItems)
            if (this.settings.images && !this.settings.Thumbnail) {
                yield this.setProfileImages();
            }
            // }
            // }, 1000);
            // }
            // console.log(this.repeatItems)
            setTimeout(() => {
                if (this.settings && this.settings.displayLayout && this.settings.displayLayout != "") {
                    jQuery('#innerDiv').append(this.repeaterDiv.nativeElement);
                    let search_results = '';
                    jQuery('.removeMeParent .removeMe').each(function () {
                        search_results = search_results + jQuery(this).html();
                    });
                    jQuery('#innerDiv').html(search_results);
                }
            }, 100);
        });
    }
    // get the class name for dropdowns
    getClassName(searchFilter) {
        if (searchFilter != undefined && searchFilter['data'] != undefined && searchFilter['data']['FilterType'] != undefined) {
            return searchFilter.data.FilterType;
        }
        else {
            return "";
        }
    }
    clearAllAdvanceFilters() {
        //  console.log(this.AdvanceSearchFilters)
        this.AdvanceSearchFilters.map(ele => {
            //  console.log(ele)
            ele.searchFilterValues = [];
        });
        this.searchAdvanceFilter = "";
        this.isFitBound = true;
        this.NoData = false;
        this.isShowOrHide = false;
    }
    getIsclearAllAdvanceFilters() {
        let isShowOrHide = false;
        if (this.AdvanceSearchFilters.length > 0) {
            this.AdvanceSearchFilters.map((ele, index) => {
                if (ele.searchFilterValues.length > 0) {
                    isShowOrHide = true;
                }
                if (this.AdvanceSearchFilters.length == index + 1) {
                    if (isShowOrHide) {
                        this.isShowOrHide = true;
                        return true;
                    }
                    else if (this.searchAdvanceFilter == "") {
                        this.isShowOrHide = false;
                        return false;
                    }
                    else {
                        this.isShowOrHide = true;
                        return true;
                    }
                }
            });
        }
        else {
            if (this.searchAdvanceFilter != "") {
                this.isShowOrHide = true;
                return true;
            }
            else {
                this.isShowOrHide = false;
                return false;
            }
        }
    }
    getInputButtonValue() {
        this.returnURL = "/Staff/CreateCommunication?query=" + this.settings.InputIQA + "&queryparams=%5B"; // 1
        this.IqaPagedParameters.Result.Parameters.$values.map((paramObj, paramIndex) => {
            if (paramObj.PropertyName != 'ID') {
                this.returnURL = this.returnURL + "%7B" + "%22Item1%22%3A%22" + paramObj.PropertyName + "%22%2C%22Item2%22%3A%22%22" + "%7B%2C";
            }
        });
        this.returnURL = this.returnURL + "%7B" + "%22Item1%22%3A%22ID" + "%22%2C%22Item2%22%3A%22";
        this.recordsBigArray.map((ele, index) => {
            if (this.recordsBigArray.length == index + 1) {
                this.returnURL = this.returnURL + "%22" + ele.ID + "%22";
            }
            else {
                this.returnURL = this.returnURL + "%22" + ele.ID + "%22%2C";
            }
        });
        this.returnURL = this.returnURL + "%22%7B%5D";
        this.returnURL = this.returnURL + "&ReturnUrl=" + window.location.pathname + window.location.search + ";";
        window.location.href = this.returnURL;
    }
}

import { FilterData } from './FilterData';



export class RadiusSearchSettings {
    public GoogleKey: string;
    public MapPosition: string;
    public MapCssClass: string;
    public FilterCssClass: string;
    public AdvancedFilterCssClass: string;
    public MapWidth: number;
    public MapHeight: number;
    public Licence: string;
    public Country: string;
    
    public InputIQA: string;
    public GoogleAddressColumn: string;
    public PostcodeZipColumn: string;
    public GooglePinBubbleColumn: string;
    public GooglePinHoverTextColumn: string;
    public DefaultRadius: number;
    public ContactIDColumn: string;

    public CityZipLabel: string;
    public CityZipIQA: string;
    public PostcodeValueColumn: string;
    public PostcodeDescriptionColumn: string;
    public RadiusLabel: string;
    public DistanceType: string;
    public RadiusIQA: string;
    public RadiusValueColumn: string;
    public RadiusDescriptionColumn: string;
    public SearchFilters: FilterData[];
    public AdvanceSearchFilters: FilterData[];
    public advGeneralLabel: string;

    public ResultsPerPage: number;
    public EnableEmail: boolean;
    public displayLayout: string;
    public OpeningHTML: string;
    public RepeaterHTML: string;
    public ClosingHTML: string;

    public RandomizeResults: boolean;
    public isSortByDistance: boolean;

    public ContactKeyColumn:string;
    public images: string;
    public Thumbnail: string;
    public withoutRadius: string;
    public isHideMap: boolean;
    public isNoMap: boolean;
    public isRecaptcha: boolean;
    public SiteKey: string;
}
<div class="display-system-wrap p-3 p-md-4 pseudoRadiusDisplay">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous">

    <!-- <h2 class="heading-main mb-2 mb-sm-3 mb-md-4 text-center">Display - Search (Advanced Filter Toggle
    {{isAdvancedSearchFilter ? 'On' : 'Off'}})</h2> -->
    <!--Filter Fields Design Starts-->
    

    <div class="blur_container">

 

        <div class="blur_container_item" *ngIf="!isRecaptcha">
    <form [formGroup]="aFormGroup" class="my-5 recaptchaItem">
        <ngx-recaptcha2 #captchaElem
        [siteKey]="siteKey"
        (success)="handleSuccess($event)"
          formControlName="recaptcha">
        </ngx-recaptcha2>
      </form>
    </div>


      
  

        
    
    <div class="filter-row mb-4" >
        <div class="row d-block" *ngIf="!isRadius">
            <div class="col-sm-8">

                <div class="field-wrap">

                    <mat-form-field class="inner_result_media">
                        <mat-label>{{settings.CityZipLabel ? settings.CityZipLabel : 'Suburb or Postcode'}}</mat-label>
                        <input class="input_style" aria-autocomplete="none" matInput id="txtLocationSearchField" [placeholder]="settings.CityZipLabel ? settings.CityZipLabel : 'Suburb or Postcode'" [formControl]="SuburbOrPostcode" (blur)="checkCity($event.target.value)">
                    </mat-form-field>
                    

                    <mat-error *ngIf="blurValidation" class="error_new">
                        for "{{settings.CityZipLabel ? settings.CityZipLabel : 'Suburb or Postcode'}}", please select an option from drop-down
                    </mat-error>
                    <mat-error *ngIf="!blurValidation && (SuburbOrPostcode.value == '' || SuburbOrPostcode.value == null) && checkIsValid" class="error_new">
                        Please select {{settings.CityZipLabel ? settings.CityZipLabel : 'Suburb or Postcode'}}
                    </mat-error>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="field-wrap">
                    <mat-form-field class="w-100">
                        <mat-label>{{settings.RadiusLabel ? settings.RadiusLabel : 'Radius'}}</mat-label>
                        <mat-select class="form-fld" (ngModelChange)="citySuburbRadiuschange($event)" [(ngModel)]="citySuburbRadius">
                            <mat-option *ngFor="let item of radius" [value]="item[settings.RadiusValueColumn]">
                                {{item[settings.RadiusDescriptionColumn]}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="citySuburbRadius == '' && checkIsValid">Please select {{settings.RadiusLabel ? settings.RadiusLabel : 'Radius'}}</mat-error>
                </div>
            </div>
        </div>

        <!-- Simple Search Filters Starts -->
        <div class="row">
           
            <ng-container *ngFor="let searchFilter of SearchFilters; let z = index">

                <div class="col-xl-6 col-sm-6 mb-3" [ngClass]="getClassName(searchFilter)" *ngIf="searchFilter && searchFilter.data">
                    <ng-container *ngIf="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'singleselectautocomplete';">
                        <div class="{{settings.FilterCssClass}} row form-row multi-select-drop">
                            <div class="col-lg-3">
                                <label class="mt-lg-2 mb-0 mt-md-0"> {{searchFilter.data.FilterLabel}} </label>
                            </div>
                            <div class="col-lg-9">
                                <div class="media">
                                    <div class="media-body">
                                        <ng-multiselect-dropdown appFocusOnClick color="accent" class="w-100" [data]="searchFilter.dropdownData" [(ngModel)]="searchFilter.searchFilterValues" [settings]="searchFilter.dropdownSettings">
                                        </ng-multiselect-dropdown>
                                        <mat-error *ngIf="searchFilter.data.IsMandatory && searchFilter.searchFilterValues == '' && checkIsValid">
                                            Please select {{searchFilter.data.FilterLabel}}</mat-error>
                                    </div>

                                    <div class="infoTip mt-2 ml-2 " *ngIf="searchFilter.data.Tooltip !== ''">
                                        <fa-icon [icon]="faInfoCircle" class='fa-lg' [matTooltip]="searchFilter.data.Tooltip" matTooltipPosition="left" matTooltipClass="tooltip-white"></fa-icon>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf=" searchFilter && searchFilter.data && searchFilter.data.FilterType == 'checkedBox'">

                        <div class="{{settings.FilterCssClass}} row form-row multi-select-drop">
                            <div class="col-lg-3">
                                <label class="mt-lg-2 mb-0 mt-md-0"> {{searchFilter.data.FilterLabel}} </label>
                            </div>
                            <div class="col-lg-9">

                                <mat-checkbox [(ngModel)]="searchFilter.value" class="example-margin"></mat-checkbox>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf=" searchFilter && searchFilter.data && searchFilter.data.FilterType == 'freeText'">
                        <div class="{{settings.FilterCssClass}} row form-row multi-select-drop">
                            <div class="col-lg-3">
                                <label class="mt-lg-2 mb-0 mt-md-0"> {{searchFilter.data.FilterLabel}}  </label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text" class="form-control input_fields" (keydown.enter)="doEvent($event);pressEnterBtn()"  [(ngModel)]="searchFilter.value" >
                                <mat-error class="mt-1" *ngIf="searchFilter.data.IsMandatory && searchFilter.value == '' && checkIsValid">
                                    Please select {{searchFilter.data.FilterLabel}}</mat-error>
                            </div>
                        </div>
                    </ng-container>
                    
                    <ng-container *ngIf=" searchFilter && searchFilter.data && searchFilter.data.FilterType == 'multiselectautocomplete'">
                        <div class="{{settings.FilterCssClass}} row form-row multi-select-drop">
                            <div class="col-lg-3">
                                <label class="mt-lg-2 mb-0 mt-md-0"> {{searchFilter.data.FilterLabel}} </label>
                            </div>
                            <div class="col-lg-9">
                                <div class="media">
                                    <div class="media-body">
                                        <ng-multiselect-dropdown appFocusOnClick color="accent" class="w-100" [data]="searchFilter.dropdownData" [(ngModel)]="searchFilter.searchFilterValues" [settings]="searchFilter.dropdownSettings">
                                        </ng-multiselect-dropdown>
                                        <mat-error *ngIf="searchFilter.data.IsMandatory && searchFilter.searchFilterValues == '' && checkIsValid">
                                            Please select {{searchFilter.data.FilterLabel}}</mat-error>
                                    </div>

                                    <div class="infoTip mt-2 ml-2" *ngIf="searchFilter.data.Tooltip !== ''">
                                        <fa-icon [icon]="faInfoCircle" class='fa-lg' [matTooltip]="searchFilter.data.Tooltip" matTooltipPosition="left" matTooltipClass="tooltip-white"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'megaMenu';">
                        <div id="megaDropClose">
                            <div class="{{settings.FilterCssClass}} row form-row multi-select-drop megaTypeMenuWidth">
                                <div class="col-lg-3">
                                    <label class="mt-lg-2 mb-0 mt-md-0"> {{searchFilter.data.FilterLabel}}  </label>
                                </div>
                                <div class="col-lg-9">
                                    <div class="media">
                                        <div class="media-body">
                                            <div class="megaMenuDropDown" (click)="isMegaSearchFilter = isMegaSearchFilter=true; do($event); checkwidth($event)">
                    
                                                <div class="auSch-col auSch-keyWords l-mp-12 l-tp-6 l-ds-5" data-tabindex="1">
                                                    <div class="aTgInp aTgInp--noIcon" data-wg="autoTagInput" data-uid="aTgInp-a65ae31c-715a-40cb-9221-69e45a09d355" data-tag-list="" data-ac-list="" data-placeholder="Select up to three issues and a location">
                                                        <p class="aTgInp-maxTagsMsg u-shadow u-pSm u-clr--mdClr9" *ngIf="megaMenuLimit <= totalMenuCount">
                                                            Maximum search items reached
                                                        </p>
                                                        <div id="aTgInp-cont-aTgInp-a65ae31c-715a-40cb-9221-69e45a09d355" class="aTgInp-content l-clearfix active">
                                                            <span class="taggle_placeholder" style="opacity: 0;">Select up to three issues and a location</span>
                                                            <ul class="taggle_list">
                                                                <ng-container *ngFor="let menu of selectMegaMenu; let selectIndex = index;let lastItem = last">
                                                                    <li class="taggle " *ngIf="selectIndex <3"><span class="taggle_text" >{{megaMenu[menu['row']].subMenu[menu['col']]}}</span><button class="close u-btnCasual u-btnCasual--nude u-btnCasual--justIcon" type="button" (click)="removeMegaMenu(menu['row'],menu['col'])"><span class="u-btnCasual-icon "> <i class="far fa-times-circle"></i></span></button>
                                                                        <input type="hidden" value="Anxiety &amp; phobias" name="taggles[]"></li>
                                                                        <li class="taggle " *ngIf="lastItem && selectIndex >=3">
                                                                            <span class="taggle_text" >more items ...</span>
                                                                        </li>
                                                                </ng-container>
                                                            </ul>
                                                            <div class="taggle_sizer" style="font-size: 18px;"></div>
                                                        </div>
                                                        <span class="aTgInp-icon  "></span>
                                                        <span class="aTgInp-loader u-hidden ldrsimple ldrsimple--sml ldrsimple--white"></span>
                                                        <input class="aTgInp-currentValue" type="hidden" name="currentValue" value="">
                                                    </div>
                                                    <span class="auSch-divText">AND</span>
                                                </div>
                                            </div>
                                            <mat-error *ngIf="searchFilter.data.IsMandatory && searchFilter.searchFilterValues == '' && checkIsValid">
                                                Please select {{searchFilter.data.FilterLabel}}
                                            </mat-error>
                                        </div>
                                        <div class="infoTip mt-2 ml-2 " *ngIf="searchFilter.data.Tooltip !== ''">
                                            <fa-icon [icon]="faInfoCircle" class='fa-lg' [matTooltip]="searchFilter.data.Tooltip" matTooltipPosition="left" matTooltipClass="tooltip-white"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4" [hidden]="!isMegaSearchFilter">
                                <div class="row">
                                    <div class="col-sm-12  megaToggle is-mouseuser">
                                        <div class="mcbMn u-shadow u-clr--xdkGry" data-wg="megaCbMenu" data-uid="mcbMn-007338554920995355">
                                            <div class="mcbMn-inner l-gridWrap no-outerPad u-shadow" style="overflow: inherit;">
                                                <!--Left Column-->
                                                <div class="mcbMn-leftCol l-tp-3 no-gut overHeight"  >
                                                    <button type="button" class="mcbMn-doneBtn u-visibleMl u-btnNude u-clr--mdClr4" (click)="isMegaSearchFilter = !isMegaSearchFilter; do($event);">
                                                        <span class="mcbMn-doneBtnIcon">  <i class="fas fa-check"></i></span>
                                                        DONE
                                                    </button>
                    
                                                    <ul class="mcbMn-menu m-0">
                                                        <li class="mcbMn-menuItem" *ngFor="let menu of megaMenu;let index =index">
                                                            <a (click)="megaMenuTab(index)" class="mcbMn-menuLink u-btnCasual u-btnCasual--iconR u-btnCasual--iconSm " [ngClass]="{'is-active': index == 0}" title="Most searched issues" data-tab="most-searched-issues">
                                                                {{menu.name}}
                                                                <span class="mcbMn-badge u-badge u-badge--notification " [ngClass]="{'u-hidden': menu.count == 0}" href="#"
                                                                    data-tab="most-searched-issues">{{menu.count}}</span>
                                                                    <i class="fas fa-angle-right right-icon"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <!--End Left Column-->
                    
                                                <!--Right Column-->
                                                <div class="mcbMn-rightCol l-tp-9 no-gut overHeight ">
                                                    <button type="button" class="mcbMn-btnBack u-visibleMl u-btnNude u-btnNude--bold u-btnNude--iconL" (click)="backMegaMenu(); do($event);">
                                                        <span class="u-btnNude-icon "><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                                        Back
                                                    </button>
                    
                                                    <button type="button" class="mcbMn-doneBtn  u-btnNude u-clr--mdClr4" (click)="isMegaSearchFilter = !isMegaSearchFilter; do($event);">
                                                        <span class="mcbMn-doneBtnIcon">  <i class="fas fa-check"></i></span>
                                                        DONE
                                                    </button>
                    
                    
                                                    <div *ngFor="let menu of megaMenu;let index =index" class="mcbMn-tabContent " [ngClass]="{'is-active': index == 0}" data-tab="most-searched-issues">
                                                        <div class="mcbMn-tabWrap l-gridWrap">
                                                            <div class="l-tp-12">
                                                                <div class="mcbMn-tabHeader">
                                                                    <div class="mcbMn-tabTitle l-floatLeft">
                                                                        <h2 class="mcbMn-tabTitleMb u-hLg u-h--unbold">{{menu.name}}</h2>
                                                                    </div>
                                                                    <a href="javascript:void(0);" class="mcbMn-btnClear l-floatRight u-btnNude u-btnNude--mdClr1UL u-btnNude--permUL" (click)="clearCheckBox()" data-tab="most-searched-issues">
                                                                        <span class="u-btnNude-ulContent">
                                                                            Clear all selections
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <!-- *ngIf="(menu.subMenu.length <megaMenuLimit) && ( megaMenuLimit - totalMenuCount >=  menu.subMenu.length )" -->
                                                                    <div  class="mcbMn-checkbox" >
                                                                        <div class="ncb u-niceCheckBox is-converted mcbMn-niceCB" data-wg="niceCheckBox" attr.data-uid="{{index}}">
                                                                            <div class="ncb-content">
                                                                                <input type="checkbox"  [disabled]="(megaMenuLimit - totalMenuCount <=  menu.subMenu.length) && (menu.subMenu.length- menu.count!=0) &&  !(menu.subMenu.length- menu.count<= megaMenuLimit - totalMenuCount) " (click)="selectAll($event,index)"  data-label="Depression" data-tab="most-searched-issues" class="mcbMn-chbInput " attr.class="selectAllbtn{{index}}"  attr.id="{{index}}">
                                                                                <label attr.for="{{index}}" class="ncb-lb">
                                                                                    <i class="fas fa-check ncb-icon"></i>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <label attr.for="{{index}}" class="ncb-textLabel u-pSm mcbMn-niceCBLabel" style="padding-left: 40px!important;">
                                                                            <strong>  Select / Deselect All  </strong>
                                                                        </label>
                                                                    </div>
                    
                                                                    <div *ngFor="let subMenu of menu.subMenu;let subIndex =index" class="mcbMn-checkbox">
                                                                        <div class="ncb u-niceCheckBox is-converted mcbMn-niceCB" data-wg="niceCheckBox" attr.data-uid="{{index}}+{{subIndex}}">
                                                                            <div class="ncb-content">
                                                                                <input type="checkbox" (click)=gotChecked($event,index,subIndex) data-label="Depression" data-tab="most-searched-issues" class="mcbMn-chbInput ncb-inp" attr.class="selectAll{{index}}" attr.id="{{index}}+{{subIndex}}">
                                                                                <label attr.for="{{index}}+{{subIndex}}" class="ncb-lb">
                                                                                    <i class="fas fa-check ncb-icon"></i>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <label attr.for="{{index}}+{{subIndex}}" class="ncb-textLabel u-pSm mcbMn-niceCBLabel" style="padding-left: 40px!important;">
                                                                            {{subMenu}}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--End Right Column-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div class="d-flex justify-content-end">
            <button id="pseudoResetbtn" mat-button color="warn" type="button" (click)="loadDefaultData(settings); do($event);">Reset</button>
            <button id="pseudoSearchbtn" mat-raised-button color="primary" *ngIf="isRecaptcha" class="ml-3" type="button" [disabled]="blurValidation || submitedValidaiton" (click)="ShowResultsSection(); do($event);">Search</button>
            <button id="pseudoAdvSearchbtn" mat-raised-button color="primary" class="ml-3" type="button" [disabled]="!isSearchFilter || matSpinLoader" (click)="isAdvancedSearchFilter = !isAdvancedSearchFilter; searchToggle(isAdvancedSearchFilter); do($event);">
        <ng-container *ngIf="matSpinLoader; else elseTemplate">
          <fa-icon [icon]="faSlidersH" class='fa-lg'></fa-icon>
          <mat-spinner class="custom-button-spinner"></mat-spinner>
        </ng-container>
        <ng-template #elseTemplate>
          <fa-icon [icon]="faSlidersH" class='fa-lg'></fa-icon>
        </ng-template>
      </button>
        </div>
        <!-- Simple Search Filters Ends -->

        <!-- Advanced Search Filters Starts -->
        <div class="toggle-advance-filter" [hidden]="!isAdvancedSearchFilter">
            <div class="row">
                <div class="col-sm-12" id="pseudoGeneralSearch">
                    <mat-form-field color="accent" class="w-100">
                        <input matInput [placeholder]="settings && settings.advGeneralLabel ? settings.advGeneralLabel : 'Search' " [(ngModel)]="searchAdvanceFilter" (keydown.enter)="doEvent($event);" (ngModelChange)="onStopSearchTyping(); getIsclearAllAdvanceFilters();">
                    </mat-form-field>
                </div>
                <ng-container *ngFor="let advanceSearchFilter of AdvanceSearchFilters">
                    <div class="col-xl-6 col-sm-6 mb-3" [ngClass]="getClassName(advanceSearchFilter)">
                        <ng-container *ngIf="advanceSearchFilter && advanceSearchFilter.data && advanceSearchFilter.data.FilterType == 'singleselectautocomplete'; else elseTemplate">
                            <div class="{{settings.AdvancedFilterCssClass}} row form-row multi-select-drop">
                                <div class="col-lg-3">
                                    <label class="mt-lg-2 mb-0 mt-md-0"> {{advanceSearchFilter.data.FilterLabel}} </label>
                                </div>
                                <div class="col-lg-9">
                                    <div class="media">
                                        <div class="media-body">
                                            <ng-multiselect-dropdown color="accent" class="w-100" [data]="advanceSearchFilter.dropdownData" [(ngModel)]="advanceSearchFilter.searchFilterValues" [settings]="advanceSearchFilter.dropdownSettings" (ngModelChange)="getSearchRecords(true); getIsclearAllAdvanceFilters();">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="infoTip mt-2 ml-2" *ngIf="advanceSearchFilter.data.Tooltip !== ''">
                                            <fa-icon [icon]="faInfoCircle" class='fa-lg' [matTooltip]="advanceSearchFilter.data.Tooltip" matTooltipPosition="left" matTooltipClass="tooltip-white"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div *ngIf="advanceSearchFilter" class="{{settings.AdvancedFilterCssClass}} row form-row multi-select-drop">
                                <div class="col-lg-3">
                                    <label class="mt-lg-2 mb-0 mt-md-0"> {{advanceSearchFilter.data.FilterLabel}} </label>
                                </div>
                                <div class="col-lg-9">
                                    <div class="media">
                                        <div class="media-body">
                                            <ng-multiselect-dropdown color="accent" class="w-100" [data]="advanceSearchFilter.dropdownData" [(ngModel)]="advanceSearchFilter.searchFilterValues" [settings]="advanceSearchFilter && advanceSearchFilter.dropdownSettings ? advanceSearchFilter.dropdownSettings : ''"
                                                (ngModelChange)="getSearchRecords(true); getIsclearAllAdvanceFilters();">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="infoTip mt-2 ml-2" *ngIf="advanceSearchFilter.data.Tooltip !== ''">
                                            <fa-icon [icon]="faInfoCircle" class='fa-lg' [matTooltip]="advanceSearchFilter.data.Tooltip" matTooltipPosition="left" matTooltipClass="tooltip-white"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- Advanced Search Filters Ends -->

        <!-- Advanced Filters Info Starts -->
        <div class="toggle-advance-filter-info mt-3" *ngIf="searchAdvanceFilterChips">
            <div class="filter-info-inner" *ngIf="searchAdvanceFilter">
                <span>Search:&nbsp;</span>
                <mat-chip-list>
                    <mat-chip>
                        {{ searchAdvanceFilter }}
                        <mat-icon matChipRemove (click)="searchAdvanceFilter='';isFitBound = true; onStopSearchTyping(); do($event);">cancel
                        </mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>

            <div *ngFor="let searchFilter of AdvanceSearchFilters; let j = index;">

                <ng-container *ngIf="searchFilter && searchFilter.data.FilterType == 'singleselectautocomplete'; else elseTemplate">

                    <div class="filter-info-inner" *ngIf="searchFilter.searchFilterValues.length > 0">
                        <span>{{searchFilter.data.FilterLabel}}:&nbsp;</span>
                        <mat-chip-list>
                            <mat-chip *ngFor="let filterItem of searchFilter.searchFilterValues; let i = index;">
                                {{ filterItem[searchFilter.data.PostcodeDescriptionColumn] }}
                                <mat-icon matChipRemove (click)="searchFilter.searchFilterValues = []; do($event);">cancel</mat-icon>
                            </mat-chip>
                            <!-- <a (click)="searchFilter.searchFilterValues = []; do($event);" style="cursor: pointer; color: #007bff;"
                class="ml-4 mt-1">clear all</a> -->
                        </mat-chip-list>
                    </div>
                </ng-container>
                <ng-template #elseTemplate>
                    
                    <div class="filter-info-inner" *ngIf="searchFilter && searchFilter.searchFilterValues.length > 0">
                        <span>{{searchFilter.data.FilterLabel}}:&nbsp;</span>
                        <mat-chip-list>
                            <mat-chip *ngFor="let filterItem of searchFilter.searchFilterValues; let i = index;">
                                {{ filterItem[searchFilter.data.PostcodeDescriptionColumn] }}
                                <mat-icon matChipRemove (click)="removeAdvanceSearchData(j, i); do($event);">cancel</mat-icon>
                            </mat-chip>
                            <!-- <a (click)="searchFilter.searchFilterValues = []; do($event);" style="cursor: pointer; color: #007bff;"
                class="ml-4 mt-1">clear all</a> -->
                        </mat-chip-list>
                    </div>
                </ng-template>
            </div>

            <div class="filter-info-inner" *ngIf="isShowOrHide">
                <a (click)="clearAllAdvanceFilters();onStopSearchTyping(); do($event);" style="cursor: pointer; color: #007bff;" class="ml-4 mt-1">clear all</a>
            </div>
        </div>
        <!-- Advanced Filters Info Ends -->
    </div>
    <!--Filter Fileds Design Ends-->


    <!-- {{centerLat}}  and {{centerLng}} -->
    <!-- Google Map Design Starts -->

    <div class="list-view data-view mb-4 noMap" id="agmMapClass" *ngIf="mapAPIkeyReady">
        <agm-map *ngIf="isFitBound" #googleMap [scrollwheel]="false" [latitude]="centerLat" [longitude]="centerLng" [zoom]="zoom" class="{{settings.MapCssClass}}" [fitBounds]="isFitBound ?  true : false" (centerChange)="centerChanged($event)" [ngStyle]="{'height': settings.MapHeight + 'px', 'width': settings.MapWidth + '%'}">

            <agm-marker *ngFor="let m of DisplayViewData; let i = index" [latitude]="m.lat" [longitude]="m.lng" (markerClick)="showInfoWindow(infoWindow, i, m)" [title]="m[settings.GooglePinHoverTextColumn]" [agmFitBounds]="true">
                <agm-info-window #infoWindow (infoWindowClose)="mapWindowClosed(infoWindow, i, m)" [isOpen]="m.isSelected">

                    <p [innerHTML]="m[settings.GooglePinBubbleColumn]"></p>
                </agm-info-window>
            </agm-marker>
        </agm-map>
        <agm-map *ngIf="!isFitBound" #googleMap [scrollwheel]="false" [latitude]="centerLat" [longitude]="centerLng" [zoom]="zoom" class="{{settings.MapCssClass}}" [fitBounds]="isFitBound ?  true : false" (centerChange)="centerChanged($event)" [ngStyle]="{'height': settings.MapHeight + 'px', 'width': settings.MapWidth + '%'}">

            <agm-marker *ngFor="let m of DisplayViewData; let i = index" [latitude]="m.lat" [longitude]="m.lng" (markerClick)="showInfoWindow(infoWindow, i, m)" [title]="m[settings.GooglePinHoverTextColumn]" [agmFitBounds]="true">
                <agm-info-window #infoWindow (infoWindowClose)="mapWindowClosed(infoWindow, i, m)" [isOpen]="m.isSelected">

                    <p [innerHTML]="m[settings.GooglePinBubbleColumn]"></p>
                </agm-info-window>
            </agm-marker>
        </agm-map>
        <agm-map *ngIf="!isFitBound && firstMap" #googleMap [scrollwheel]="false" [latitude]="centerLat" [longitude]="centerLng" [zoom]="zoom" class="{{settings.MapCssClass}}" [fitBounds]="isFitBound ?  true : false" (centerChange)="centerChanged($event)" [ngStyle]="{'height': settings.MapHeight + 'px', 'width': settings.MapWidth + '%'}">

            <agm-marker *ngFor="let m of DisplayViewData; let i = index" [latitude]="m.lat" [longitude]="m.lng" (markerClick)="showInfoWindow(infoWindow, i, m)" [title]="m[settings.GooglePinHoverTextColumn]" [agmFitBounds]="true">
                <agm-info-window #infoWindow (infoWindowClose)="mapWindowClosed(infoWindow, i, m)" [isOpen]="m.isSelected">

                    <p [innerHTML]="m[settings.GooglePinBubbleColumn]"></p>
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
    <!-- Google Map Design Ends -->
    <div *ngIf="NoData" class="alert alert-danger">
        {{errorMessage}}
        <!-- No data found. Please try with a different criteria. -->
    </div>

    <!-- List View Starts -->
    <div *ngIf="settings.displayLayout == 'oldList' && DisplayViewData.length > 0">
        <div class="list-view data-view mb-4">
            <div class="media mb-sm-4 mb-3" *ngFor="let item of DisplayViewData" [style.background-color]="getColor(item.isSelected)">
                <div class="media-left text-center mr-sm-3 mr-2">
                    <div class="img-wrap d-flex align-items-center justify-content-center">
                        <img src="https://d1qtx34w5zi8yw.cloudfront.net/RadiusSearchDisplay/assets/team_2-420x460.jpg" alt="{{item.FullName}}" class="img-fluid">
                        <!--If Image is not present all first letter of first name and last name each-->
                        <!-- <span> {{getNameFirstLetter(item.FullName)}} </span> -->
                    </div>

                </div>
                <div class="media-body">
                    <div class="media-heading mb-2 mb-sm-3">
                        <h4 class="m-0"> {{item.FullName}} </h4>
                        <p class="m-0"> {{item.Email}} </p>
                    </div>
                    <div class="speciality mb-2 mb-sm-3">
                        <p class="m-0"><strong>WorkPhone: </strong> {{item.WorkPhone}} </p>
                        <p class="m-0"><strong>FullAddress: </strong> {{item.FullAddress}} </p>
                    </div>
                    <!-- <div class="speciality mb-2 mb-sm-3">
          <p class="m-0"><strong>Special interests: </strong>
            <span *ngIf="item.specialInterests.length <= 150">
              {{item.specialInterests}}
            </span>
            <span *ngIf="item.specialInterests.length > 150 && !item.showMore" class="show-text">
              {{item.specialInterests | slice:0:150}}...
              <button mat-button color="primary" (click)="item.showMore = true; do($event);">Show More</button>
            </span>
            <span *ngIf="item.specialInterests.length > 150 && item.showMore" class="show-text">
              {{item.specialInterests}}
              <button (click)="item.showMore = false; do($event);">Show Less</button>
            </span>
          </p>
        </div> -->

                    <div class="phone-map row form-row">
                        <!-- <div class="phn-wrap col-sm-12 mb-2">
            <p class="m-0 d-flex align-items-center">
              <fa-icon [icon]="faMapMarkerAlt" class='fa-icon'></fa-icon>
              <span> {{item.address}} </span>
            </p>
            <p class="m-0 d-flex align-items-center">
              <fa-icon [icon]="faPhoneAlt" class='fa-icon'></fa-icon>
              <span> {{item.phone}} </span>
            </p>
          </div> -->
                        <div class="col-sm-12">
                            <button mat-mini-fab class="mr-2 fab-orng" title="{{item.FullAddress}}" (click)="openGoogleMapBubble(item.ID); do($event);">
                <fa-icon [icon]="faGlobeAmericas" class='fa-icon'></fa-icon>
              </button>
                            <a href="#" mat-mini-fab class="mr-2 fab-orng" title="{{item.WorkPhone}}">
                                <fa-icon [icon]="faPhoneAlt" class='fa-icon'></fa-icon>
                            </a>
                            <button mat-raised-button color="primary" class="mt-2 mt-sm-0" type="button" (click)="do($event);">Views
                Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-view data-view mb-4" *ngIf="recordsBigArray.length > 0">
            <mat-paginator #paginator [length]="recordsBigArray.length" [pageSize]="settings.ResultsPerPage" (page)="pageChange($event)">
            </mat-paginator>
        </div>
    </div>
    <!-- List View Ends -->

    <!-- Card View Starts -->
    <div *ngIf="settings.displayLayout == 'oldCards' && DisplayViewData.length > 0">
        <div class="card-view data-view mb-4">
            <div class="row">
                <div class="col-lg-4 col-sm-6 mb-sm-4 mb-3" *ngFor="let item of DisplayViewData">
                    <div class="card-inner" [style.background-color]="getColor(item.isSelected)">
                        <div class="media-heading text-center">
                            <div class="img-wrap d-flex align-items-center justify-content-center">
                                <img src="https://d1qtx34w5zi8yw.cloudfront.net/RadiusSearchDisplay/assets/team_2-420x460.jpg" alt="{{item.FullName}}" class="img-fluid">
                                <!--If Image is not present all first letter of first name and last name each-->
                                <!-- <span> {{getNameFirstLetter(item.FullName)}} </span> -->
                            </div>

                        </div>
                        <div class="data-wrap p-2 p-sm-3 p-md-4">
                            <div class="data-spec mb-2 mb-sm-3 text-center">
                                <h4 class="m-0"> {{item.FullName}} </h4>
                                <p class="m-0"> {{item.Email}} </p>
                            </div>
                            <div class="speciality mb-2 mb-sm-3 text-center">
                                <p class="m-0"><strong>WorkPhone: </strong> {{item.WorkPhone}} </p>
                                <p class="m-0"><strong>FullAddress: </strong> {{item.FullAddress}} </p>
                            </div>
                            <!-- <div class="speciality mb-2 mb-sm-3">
              <p class="m-0"><strong>Special interests: </strong>
                <span *ngIf="item.specialInterests.length <= 150">
                  {{item.specialInterests}}
                </span>
                <span *ngIf="item.specialInterests.length > 150 && !item.showMore" class="show-text">
                  {{item.specialInterests | slice:0:150}}...
                  <button (click)="item.showMore = true; do($event);">Show More</button>
                </span>
                <span *ngIf="item.specialInterests.length > 150 && item.showMore" class="show-text">
                  {{item.specialInterests}}
                  <button (click)="item.showMore = false; do($event);">Show Less</button>
                </span>
              </p>
            </div> -->
                            <div class="phone-map row form-row pt-md-3 pt-2">
                                <!-- <p class="m-0 d-flex align-items-center phn-wrap">
                <fa-icon [icon]="faMapMarkerAlt" class='fa-icon'></fa-icon>
                <span> {{item.address}} </span>
              </p>
              <p class="m-0 d-flex align-items-center phn-wrap">
                <fa-icon [icon]="faPhoneAlt" class='fa-icon'></fa-icon>
                <span> {{item.phone}} </span>
              </p> -->
                                <div class="col-sm-12 text-center">
                                    <button href="#" (click)="openGoogleMapBubble(item.ID); do($event);" mat-mini-fab class="mx-1 fab-orng" title="{{item.FullAddress}}">
                    <fa-icon [icon]="faGlobeAmericas" class='fa-icon'></fa-icon>
                  </button>
                                    <a href="#" (click)="do($event);" mat-mini-fab class="mx-1 fab-orng" title="{{item.WorkPhone}}">
                                        <fa-icon [icon]="faPhoneAlt" class='fa-icon'></fa-icon>
                                    </a>
                                    <button mat-raised-button color="primary" class="mx-1" type="button" (click)="do($event);">Views
                    Details</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="data-foot row no-gutters align-items-center">
            <div class="col-4 text-center card-map">
              <button mat-icon-button>
                <fa-icon [icon]="faMapPin"></fa-icon>
              </button>
            </div>
            <div class="col-8 text-center card-details">
              <button mat-flat-button>View Details</button>
            </div>
          </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card-view data-view mb-4" *ngIf="recordsBigArray.length > 0">
            <mat-paginator #paginator [length]="recordsBigArray.length" [pageSize]="settings.ResultsPerPage" (page)="pageChange($event)">
            </mat-paginator>
        </div>
    </div>
    <!-- Card View Ends -->

    <!-- Tabular View Starts -->
    <div *ngIf="settings.displayLayout == 'oldTabular' && DisplayViewData.length > 0">
        <div class="table-view data-view mb-4">
            <div class="table-wrap">
                <div class="table-responsive">
                    <table class="mat-table table mb-0">
                        <thead>
                            <tr>
                                <th scope="col" class="image-th"></th>
                                <th scope="col" class="name-th">Name</th>
                                <th scope="col" class="mail-th">Email</th>
                                <th scope="col" class="phone-th">Work Phone</th>
                                <th scope="col" class="address-th">Address</th>
                                <th scope="col" class="action-th"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of DisplayViewData" [style.background-color]="getColor(item.isSelected)">
                                <td>
                                    <div class="icon-wrap">
                                        <img src="https://d1qtx34w5zi8yw.cloudfront.net/RadiusSearchDisplay/assets/team_2-420x460.jpg" alt="{{item.FullName}}" class="img-fluid">
                                    </div>
                                </td>
                                <td>{{item.FullName}}</td>
                                <td>{{item.Email}}</td>
                                <td>{{item.WorkPhone}}</td>
                                <td>{{item.FullAddress}}</td>
                                <td>
                                    <div class="text-right actions">
                                        <!-- <button mat-icon-button color="primary" class="m-1 m-sm-2"
                    [matTooltip]="getTooltipData(item.specialInterests)" matTooltipPosition="left"
                    matTooltipClass="tooltip-white">
                    <fa-icon [icon]="faInfo" class="icon"></fa-icon>
                  </button> -->
                                        <button (click)="openGoogleMapBubble(item.ID); do($event);" mat-mini-fab class="mx-1 fab-orng" title="{{item.FullAddress}}">
                      <fa-icon [icon]="faGlobeAmericas" class='fa-icon'></fa-icon>
                    </button>
                                        <button mat-icon-button color="primary" class="m-1">
                      <fa-icon [icon]="faMapMarkerAlt" class='fa-icon'></fa-icon>
                    </button>
                                        <button mat-raised-button color="primary" class="m-1" (click)="do($event);"> View </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="table-view  data-view mb-4" *ngIf="recordsBigArray.length > 0">
            <mat-paginator #paginator [length]="recordsBigArray.length" [pageSize]="settings.ResultsPerPage" (page)="pageChange($event)">
            </mat-paginator>
        </div>
    </div>
    <!-- Tabular View Ends -->

    <!-- Custom View Starts -->
    <div *ngIf="SurroundingHTML" #surroundingDiv id='surroundingDiv' [innerHTML]="SurroundingHTML | keepHtml : 'html'">

    </div>
    <div #repeaterDiv class="removeMeParent">
        <div *ngFor="let item of repeatItems" [innerHTML]="item | keepHtml : 'html'" class="removeMe"></div>
    </div>
    <div class="card-view data-view mb-4" *ngIf="recordsBigArray.length > 0">
        <mat-paginator #paginator [length]="recordsBigArray.length" [pageSize]="settings.ResultsPerPage" (page)="pageChange($event)">
        </mat-paginator>
        <input type="button" *ngIf="settings.EnableEmail" name="ctl01$TemplateBody$WebPartManager1$gwpciDirectory$ciDirectory$PeopleSearch$ButtonEmail" value="Email" (click)="getInputButtonValue();" id="ctl01_TemplateBody_WebPartManager1_gwpciDirectory_ciDirectory_PeopleSearch_ButtonEmail"
            title="Email" class=" TextButton" translate="yes" style="float: right;">
    </div>
    <!-- Custom View Ends -->

     

     



    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { RadiusSearchDisplayComponent } from './Components/radius-search-display/radius-search-display.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { RadiusSearchSettings } from './models/RadiusSearchSettings';
import { EscapeHtmlPipe } from './Components/radius-search-display/keep-html.pipe';
import { GeoCodeService } from './Services/geo-code.service';
import { RadiusDisplayService } from './Services/radius-display.service';
import { BehaviorSubject } from 'rxjs';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FocusOnClickDirective } from './Services/focus-on-click.directive';


@Injectable({
  providedIn: 'root'
})
export class GoogleMapsConfig {
  apiKey: string;
  public libraries: string[];
  mapAPIkeyReady: boolean

  constructor(public radiusDisplayService : RadiusDisplayService) {
     this.radiusDisplayService.getSettings().subscribe(x => {
      if(x){
        this.apiKey = x.GoogleKey
        this.libraries = ["places"]
        this.radiusDisplayService.KeyGet(x)
      }
    })
  }
}


@NgModule({
  declarations: [
    AppComponent,
    RadiusSearchDisplayComponent,
    EscapeHtmlPipe,
    FocusOnClickDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    NgxMatSelectSearchModule,
    AgmCoreModule.forRoot(),

    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [GeoCodeService,{provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig}],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
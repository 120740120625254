import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from './service-base';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./context.service";
export class VersionService extends ServiceBase {
    constructor(http, contextService) {
        super(contextService);
        this.http = http;
    }
    getVersion() {
        let url = this.getUrl("SystemParam");
        let params = new HttpParams().set("ParameterName", "eq:System_Control.Version");
        let headers = this.getHeaders();
        return this.http.get(url, { params, headers }).pipe(map((res) => res.Items.$values[0].ShortValue)).pipe(catchError(this.handleError));
        //return this.http.get(url,{params,headers}).pipe(map((res:PagedResult)=>res)).pipe(catchError(this.handleError));
    }
}
VersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ContextService)); }, token: VersionService, providedIn: "root" });

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { ServiceResponse, VirtualFileData } from '../interfaces/asi.interfaces';
import { ContextService } from './context.service';
import { ServiceBase } from './service-base';

@Injectable({
  providedIn: 'root'
})
export class PartyImageService extends ServiceBase  {

  constructor(private http: HttpClient, contextService: ContextService) { 
    super(contextService);
  }
  public getPartyImageCollection(contactKeys:string[]):Observable<any>{
    let contactKey = contactKeys[0];
    return this.getPartyImagePath(contactKey).pipe(
      flatMap((col)=>{
        let allResults = new Array(); 
        allResults.push({contactKey:col});
        for(var i=1;i<contactKeys.length;i++){
          let contactKey = contactKeys[i];
          allResults.push(this.getPartyImagePath(contactKey).pipe(
            map((file:VirtualFileData)=>{
              return {contact:file.VirtualPath};
            })
            ,catchError((err)=>{
                  console.log('Caught inner error:',err);
                  return of({contact:''});
                })
            ));
        }      
        return forkJoin(allResults);
      }),
      map((allResponses)=>{  
        return of([]);
      }),
      catchError((err)=>{
        return of([]);
      }));
    
  }
  public   getPartyImagePath(contactKey:string):Observable<VirtualFileData>{
   
    let headers = this.getHeaders();
    let url = this.getUrl('PartyImage/_execute');
    let body = {
        "$type": "Asi.Soa.Core.DataContracts.GenericExecuteRequest, Asi.Contracts",
        "OperationName": "GetProfileVirtualFile",
        "EntityTypeName": "PartyImage",
        "Parameters": {
            "$type": "System.Collections.ObjectModel.Collection`1[[System.Object, mscorlib]], mscorlib",
            "$values": [
                {
                    "$type": "System.String",
                    "$value": ""
                }
            ]
        },
        "ParameterTypeName": {
            "$type": "System.Collections.ObjectModel.Collection`1[[System.String, mscorlib]], mscorlib",
            "$values": [
                "System.String"
            ]
        },
        "UseJson": false
    };
    body.Parameters.$values[0].$value=contactKey;
     return  this.http.post(url, body, {headers:headers}).pipe(map((res: any) => { 
      
      return res.Result; }));
  }



  public  async getPartyImagePath2(contactKey:string){
   
   
    return new Promise(resolve => {
     
      let data =  this.getPartyImagePath(contactKey).subscribe(res=>{
        
        resolve(res)

      })
    })
      
        
     
   
    
  }
}

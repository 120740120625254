import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of, forkJoin } from 'rxjs';
import { PagedResult, QueryDefinitionData, List } from '../interfaces/asi.interfaces';
import { map, catchError, flatMap } from 'rxjs/operators';
import { ServiceBase } from './service-base';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./content-keys.service";
import * as i3 from "./context.service";
export class RadiusDisplayService extends ServiceBase {
    constructor(http, contentKeysService, contextService) {
        super(contextService);
        this.http = http;
        this.contentKeysService = contentKeysService;
        //live: boolean = true;
        // mapAPIkeyReady: boolean
        this.showGoogleKeyMap = new BehaviorSubject('');
        this.showGoogleKeyMaps = this.showGoogleKeyMap.asObservable();
        //if (this.live) this.getContext();//for live stuff, comment out for local faked data
    }
    getSettings() {
        this.isloader;
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('contentKey', this.contentKeysService.contentKey)
            .set('contentItemKey', this.contentKeysService.contentItemKey);
        var url = this.getUrl('ContentItem');
        return (this.http.get(url, { params, headers })
            .pipe(map((res) => res.Items.$values[0].Data.Settings), catchError(this.handleError)));
    }
    // fetch the postcode data thorugh IQA query
    getPostcodesCities(IQAQuery) {
        return [];
    }
    // fetch the radius data thorugh IQA query
    getRadius(IQAQuery) {
        return [];
    }
    // fetch the languages data thorugh IQA query
    getLanguages(IQAQuery) {
        return [];
    }
    // fetch the colours data thorugh IQA query
    getColours(IQAQuery) {
        return [];
    }
    // fetch the Education Level data thorugh IQA query
    getEducationLevel(IQAQuery) {
        return [];
    }
    // fetch the Member Types data thorugh IQA query
    getMemberTypes(IQAQuery) {
        return [];
    }
    // fetch the Specialities data thorugh IQA query
    getSpecialities(IQAQuery) {
        return [];
    }
    // get the zipcodes as per the postcode and radius
    getZipcodes() {
        return [];
    }
    // get the zipcodes from the live API
    radiusZipcodeSearch(data) {
        if (data.countryCode.toLowerCase() == 'gb' || data.countryCode.toLowerCase() == 'uk' || data.countryCode.toLowerCase() == 'au') {
            return this.radiusZipcodeSearchAUUK(data);
        }
        let apiUrl = 'https://ezcmd.com/apps/api_geo_postal_codes/nearby_locations_by_zip_code/d77dbbf3b1a6a137327af859af9dd2b8/708?zip_code=' + data.zipCode + '&country_code=' + data.countryCode + '&unit=' + data.DistanceType + '&within=' + data.radius;
        return this.http.get(apiUrl);
    }
    radiusZipcodeSearchAUUK(data) {
        // https://www.nadeemh.com/pseudocode/radiussearch/postcodelookup/au/3053/20
        // https://www.nadeemh.com/pseudocode/radiussearch/postcodelookup/au/3000/100 /
        let apiUrl = 'https://www.nadeemh.com/pseudocode/radiussearch/postcodelookup/' + data.countryCode.toLowerCase() + '/' + data.zipCode + '/' + data.radius + '/';
        // let apiUrl = 'https://www.nadeemh.com/pseudocode/radiussearch/api2/'+data.countryCode.toLowerCase()+'/'+data.zipCode+'/'+data.locality+'/'+data.radius+'/?postcodesonly' 
        //let apiUrl = 'https://www.nadeemh.com/pseudocode/radiussearch/api-distinct/gb/'+data.zipCode+'/'+data.locality+'/'+data.radius+'/';
        return this.http.get(apiUrl);
    }
    getIqaResults(iqaPath, parameters) {
        let url = this.getUrl('iqa');
        const headers = this.getHeaders();
        const params = new HttpParams(); //.set('QueryName', iqaPath);
        url += '?QueryName=' + iqaPath;
        parameters.forEach((value, key) => {
            url += '&parameter=' + value;
        });
        return this.http.get(url, { headers: headers, params: params }).pipe(map((res) => res))
            .pipe(catchError(this.handleError));
    }
    getIqaPagedZipResults(iqaPath, zipArray, zipParmName, parameters, pageOffset, pageSize) {
        let url = this.getUrl('iqa');
        const headers = this.getHeaders();
        const params = new HttpParams(); //.set('QueryName', iqaPath);
        let zipParm = zipArray;
        // console.log("step 22222 ",parameters)
        let newParms = new Map();
        //newParms['parameter0'] = zipParm;
        // debugger
        for (var i = 0; i < Object.getOwnPropertyNames(parameters).length; i++) {
            let name = Object.getOwnPropertyNames(parameters)[i];
            let currPam = parameters[name];
            // if(name == zipParmName)
            //   currPam = zipParm;
            let parmVal = currPam && currPam.length > 0 ? currPam : '';
            newParms['parameter' + (i + 1).toString()] = parmVal;
        }
        // console.log(newParms)
        return this.getIqaPagedResults(iqaPath, newParms, pageOffset, pageSize).pipe(flatMap((result) => {
            let allResults = new Array();
            //store first set of results
            allResults.push(of(result.Items.$values));
            // for(var i=1;i<zipArray.length;i++){
            //       setTimeout(() => {
            //         if(zipArray && zipArray[i] && zipArray[i].length && zipArray[i] != undefined){
            //         let zipParm = zipArray[i];
            //           let newParms:Map<string, string> = new Map<string, string>();
            //           newParms['parameter0'] = zipParm;
            //           for (var i = 0; i < Object.getOwnPropertyNames(parameters).length; i++) {
            //             let name = Object.getOwnPropertyNames(parameters)[i];
            //             let currPam = parameters[name];
            //             let parmVal = currPam.length > 0 ? currPam.split(",").join('","') + '"': ''
            //             newParms['parameter'+(i+1).toString()] = parmVal;
            //           }
            //         this.getIqaPagedResults(iqaPath,newParms,pageOffset,pageSize)
            //         .pipe(map((res:PagedResult)=>{allResults.push(res.Items.$values);}));
            //       }
            //         }, i*1000);
            // }
            return forkJoin(allResults); //join all results to be handled below    
        }), map((allResponses) => {
            //allResponses is array of arrays   
            let items = {
                $type: "System.Collections.Generic.List`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], mscorlib",
                $values: []
            };
            //Concatanate arrays collected
            let arr = new Array();
            for (var i = 0; i < allResponses.length; i++) {
                arr = arr.concat(allResponses[i]);
            }
            items.$values = arr;
            //setup iMIS paged result with concatenated results     
            let result = {
                $type: 'Asi.Soa.Core.DataContracts.PagedResult`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], Asi.Contracts',
                Items: items,
                Offset: 0,
                NextOffset: 0,
                Count: items.$values.length,
                TotalCount: items.$values.length,
                Limit: items.$values.length,
                HasNext: false,
                NextPageLink: null
            };
            return result;
        }), catchError((err) => {
            let items = {
                $type: "System.Collections.Generic.List`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], mscorlib",
                $values: []
            };
            let result = {
                $type: 'Asi.Soa.Core.DataContracts.PagedResult`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], Asi.Contracts',
                Items: items,
                Offset: 0,
                NextOffset: 0,
                Count: 0,
                TotalCount: 0,
                Limit: 0,
                HasNext: false,
                NextPageLink: null
            };
            return of(result);
        }));
    }
    getIqaPagedResults(iqaPath, parameters, pageOffset, pageSize) {
        let url = this.getUrl('iqa');
        const headers = this.getHeaders();
        const params = new HttpParams(); //.set('QueryName', iqaPath);
        let parameter = "";
        for (var i = 0; i < Object.getOwnPropertyNames(parameters).length; i++) {
            let name = Object.getOwnPropertyNames(parameters)[i];
            let currPam = parameters[name];
            let val = currPam && currPam.length > 0 ? '"' + parameters[name].split(",").join('","') + '"' : '';
            parameter += '&parameter=' + encodeURIComponent(val);
        }
        if (pageSize !== 0) {
            parameter += '&limit=' + pageSize.toString();
        }
        else {
            parameter += '&limit=500';
        }
        if (pageSize !== 0) {
            return this.http.get(url + '?QueryName=' + iqaPath + parameter, { headers })
                .pipe(map((res) => res))
                .pipe(catchError(this.handleError));
        }
        else {
            //need to page through results
            return this.getIqaPagedResultsPrivate(url + '?QueryName=' + iqaPath + parameter, headers).pipe(map((res) => res)).pipe(catchError(this.handleError));
        }
    }
    getIqaPagedResultsPrivate(url, headers) {
        return this.http.get(url, { headers }).pipe(flatMap((result) => {
            let nextOffset = result.NextOffset;
            let limit = result.Limit;
            const totalCount = result.TotalCount;
            let count = result.Count;
            let allResults = new Array();
            //store first set of results
            allResults.push(of(result.Items.$values));
            //loop through remaining 'pages'
            while (count < totalCount) {
                let nextUrl;
                if (url.includes('?')) //offset is start of next page
                    nextUrl = url + '&offset=' + count;
                else
                    nextUrl = url + '?offset=' + count;
                //if final 'page' then limit to that amount. Might not be necessary
                if (totalCount - count < limit) {
                    //nextUrl = nextUrl+'&limit='+(totalCount-count);
                }
                //add results of this page.
                allResults.push(this.http.get(nextUrl, { headers })
                    .pipe(map((resp) => {
                    return resp.Items.$values; //return array of items
                }), catchError((err) => {
                    return of([]);
                })));
                count += limit; //next page offset
            }
            return forkJoin(allResults); //join all results to be handled below    
        }), map((allResponses) => {
            //allResponses is array of arrays   
            let items = {
                $type: "System.Collections.Generic.List`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], mscorlib",
                $values: []
            };
            //Concatanate arrays collected
            let arr = new Array();
            for (var i = 0; i < allResponses.length; i++) {
                arr = arr.concat(allResponses[i]);
            }
            items.$values = arr;
            //setup iMIS paged result with concatenated results     
            let result = {
                $type: 'Asi.Soa.Core.DataContracts.PagedResult`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], Asi.Contracts',
                Items: items,
                Offset: 0,
                NextOffset: 0,
                Count: items.$values.length,
                TotalCount: items.$values.length,
                Limit: items.$values.length,
                HasNext: false,
                NextPageLink: null
            };
            return result;
        }), catchError((err) => {
            let items = {
                $type: "System.Collections.Generic.List`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], mscorlib",
                $values: []
            };
            let result = {
                $type: 'Asi.Soa.Core.DataContracts.PagedResult`1[[Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts]], Asi.Contracts',
                Items: items,
                Offset: 0,
                NextOffset: 0,
                Count: 0,
                TotalCount: 0,
                Limit: 0,
                HasNext: false,
                NextPageLink: null
            };
            return of(result);
        }));
    }
    // 
    getQueryParameters(queryPath) {
        const headers = this.getHeaders();
        const httpOptions = {
            headers: headers
        };
        //this is the POST request body for getting a (IQA) QueryDefinition.
        let findBypath = {
            "$type": "Asi.Soa.Core.DataContracts.GenericExecuteRequest, Asi.Contracts",
            "OperationName": "FindByPath",
            "EntityTypeName": "QueryDefinition",
            "Parameters": {
                "$type": "System.Collections.ObjectModel.Collection`1[[System.Object, mscorlib]], mscorlib",
                "$values": [
                    {
                        "$type": "System.String",
                        "$value": queryPath //particular query.
                    }
                ]
            },
            "ParameterTypeName": {
                "$type": "System.Collections.ObjectModel.Collection`1[[System.String, mscorlib]], mscorlib",
                "$values": [
                    "System.String"
                ]
            },
            "UseJson": false
        };
        //use baseUrl gathered earlier from ClientContent to get relative path of restful operation.
        //let url = this.baseUrl + 'api/QueryDefinition/_execute';
        let url = this.getUrl('QueryDefinition');
        url += '/_execute';
        return this.http.post(url, findBypath, httpOptions).pipe(map((res) => {
            if (res.$type == 'Asi.Soa.Core.DataContracts.ServiceResponse`1[[Asi.Soa.Core.DataContracts.QueryDefinitionData, Asi.Contracts]], Asi.Contracts')
                return res;
            return res.Result;
        }));
    }
    getIqaResultsOff(iqaPath, parameters, offset) {
        let url = this.getUrl('iqa');
        const headers = this.getHeaders();
        const params = new HttpParams(); //.set('QueryName', iqaPath);
        url += '?QueryName=' + iqaPath;
        if (parameters && parameters.values)
            parameters.forEach((value, key) => {
                url += '&parameter=' + value;
            });
        url = url + '&offset=' + offset;
        return this.http.get(url, { headers: headers, params: params }).pipe(map((res) => res))
            .pipe(catchError(this.handleError));
    }
    // get the ziplocality from AWS lambda server
    getZipLocality(country, zipLocality) {
        let apiUrl = 'https://7slaeh5lyc.execute-api.us-east-2.amazonaws.com/default/searchAPI';
        let rawData = {
            "country": country,
            "zipLocality": zipLocality
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(apiUrl, rawData, { headers })
            .pipe(map((res) => res['body']))
            .pipe(catchError(this.handleError));
    }
    handleError(error) {
        console.log(error);
        return throwError(error);
    }
    KeyGet(value) {
        this.showGoogleKeyMap.next(value);
    }
}
RadiusDisplayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RadiusDisplayService_Factory() { return new RadiusDisplayService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ContentKeysService), i0.ɵɵinject(i3.ContextService)); }, token: RadiusDisplayService, providedIn: "root" });
function urlencode(parameter) {
    throw new Error('Function not implemented.');
}

import * as tslib_1 from "tslib";
import { forkJoin, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { VirtualFileData } from '../interfaces/asi.interfaces';
import { ServiceBase } from './service-base';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./context.service";
export class PartyImageService extends ServiceBase {
    constructor(http, contextService) {
        super(contextService);
        this.http = http;
    }
    getPartyImageCollection(contactKeys) {
        let contactKey = contactKeys[0];
        return this.getPartyImagePath(contactKey).pipe(flatMap((col) => {
            let allResults = new Array();
            allResults.push({ contactKey: col });
            for (var i = 1; i < contactKeys.length; i++) {
                let contactKey = contactKeys[i];
                allResults.push(this.getPartyImagePath(contactKey).pipe(map((file) => {
                    return { contact: file.VirtualPath };
                }), catchError((err) => {
                    console.log('Caught inner error:', err);
                    return of({ contact: '' });
                })));
            }
            return forkJoin(allResults);
        }), map((allResponses) => {
            return of([]);
        }), catchError((err) => {
            return of([]);
        }));
    }
    getPartyImagePath(contactKey) {
        let headers = this.getHeaders();
        let url = this.getUrl('PartyImage/_execute');
        let body = {
            "$type": "Asi.Soa.Core.DataContracts.GenericExecuteRequest, Asi.Contracts",
            "OperationName": "GetProfileVirtualFile",
            "EntityTypeName": "PartyImage",
            "Parameters": {
                "$type": "System.Collections.ObjectModel.Collection`1[[System.Object, mscorlib]], mscorlib",
                "$values": [
                    {
                        "$type": "System.String",
                        "$value": ""
                    }
                ]
            },
            "ParameterTypeName": {
                "$type": "System.Collections.ObjectModel.Collection`1[[System.String, mscorlib]], mscorlib",
                "$values": [
                    "System.String"
                ]
            },
            "UseJson": false
        };
        body.Parameters.$values[0].$value = contactKey;
        return this.http.post(url, body, { headers: headers }).pipe(map((res) => {
            return res.Result;
        }));
    }
    getPartyImagePath2(contactKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                let data = this.getPartyImagePath(contactKey).subscribe(res => {
                    resolve(res);
                });
            });
        });
    }
}
PartyImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartyImageService_Factory() { return new PartyImageService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ContextService)); }, token: PartyImageService, providedIn: "root" });

import * as i0 from "@angular/core";
export class ContextService {
    constructor() {
        this.proxy = false;
        var data = document.querySelector('#__ClientContext').value; // document.querySelector("#__ClientContext").value;
        // we used to deserialize straight into this class, but i'm not sure how to structure this
        var appContext = JSON.parse(data);
        appContext.authToken = document.querySelector('#__RequestVerificationToken').value; //document.querySelector("#__RequestVerificationToken").value;
        this.baseUrl = appContext.baseUrl;
        this.authToken = appContext.authToken;
        //this.headers['Authorization'] = 'Bearer ' + '[Your Token goes here!!!! without brackets]'; 
        //this.headers['Content-Type'] = 'application/json';     
        this.selectedPartyId = appContext.selectedPartyId;
        this.loggedInPartyId = appContext.loggedInPartyId;
    }
}
ContextService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContextService_Factory() { return new ContextService(); }, token: ContextService, providedIn: "root" });

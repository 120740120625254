import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedResult } from '../interfaces/asi.interfaces';
import { ContextService } from './context.service';
import { ServiceBase } from './service-base';

@Injectable({
  providedIn: 'root'
})
export class VersionService extends ServiceBase {

  constructor(private http: HttpClient,contextService: ContextService) { 
    super(contextService);

   }
   public getVersion():Observable<string>{
     let url = this.getUrl("SystemParam");
     let params = new HttpParams().set("ParameterName","eq:System_Control.Version");
     let headers = this.getHeaders();
     return this.http.get(url,{params,headers}).pipe(map((res:PagedResult)=>res.Items.$values[0].ShortValue)).pipe(catchError(this.handleError));
      //return this.http.get(url,{params,headers}).pipe(map((res:PagedResult)=>res)).pipe(catchError(this.handleError));
   }
}

import * as i0 from "@angular/core";
import * as i1 from "./Services/radius-display.service";
export class GoogleMapsConfig {
    constructor(radiusDisplayService) {
        this.radiusDisplayService = radiusDisplayService;
        this.radiusDisplayService.getSettings().subscribe(x => {
            if (x) {
                this.apiKey = x.GoogleKey;
                this.libraries = ["places"];
                this.radiusDisplayService.KeyGet(x);
            }
        });
    }
}
GoogleMapsConfig.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleMapsConfig_Factory() { return new GoogleMapsConfig(i0.ɵɵinject(i1.RadiusDisplayService)); }, token: GoogleMapsConfig, providedIn: "root" });
export class AppModule {
}

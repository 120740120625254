import { Observable, from, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@agm/core";
export class GeoCodeService {
    constructor(mapLoader) {
        this.mapLoader = mapLoader;
    }
    initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }
    waitForMapsToLoad() {
        if (!this.geocoder) {
            return from(this.mapLoader.load())
                .pipe(tap(() => this.initGeocoder()), map(() => true));
        }
        return of(true);
    }
    geocodeAddress(location) {
        // let queryString = location.split(' ').join('+');
        return this.waitForMapsToLoad().pipe(
        // filter(loaded => loaded),
        switchMap(() => {
            return new Observable(observer => {
                this.geocoder.geocode({ 'address': location }, (results, status) => {
                    observer.next(results);
                    // if (status == google.maps.GeocoderStatus.OK) {
                    //   observer.next({
                    //     lat: results[0].geometry.location.lat(),
                    //     lng: results[0].geometry.location.lng()
                    //   });
                    // } else {
                    //   observer.next({ lat: 0, lng: 0 });
                    // }
                    observer.complete();
                });
            });
        }));
    }
}
GeoCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeoCodeService_Factory() { return new GeoCodeService(i0.ɵɵinject(i1.MapsAPILoader)); }, token: GeoCodeService, providedIn: "root" });

import * as i0 from "@angular/core";
export class ContentKeysService {
    // Grab our setting keys for this iPart from the DOM
    constructor() {
        const ck = document.querySelector("#x-contentKey").value;
        const cik = document.querySelector("#x-contentItemKey").value;
        this.contentKey = ck;
        this.contentItemKey = cik;
    }
}
ContentKeysService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentKeysService_Factory() { return new ContentKeysService(); }, token: ContentKeysService, providedIn: "root" });
